import React, {useEffect, useMemo, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Stack} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LinearProgress from '@mui/material/LinearProgress';
import {FiberNew} from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Popover from '@mui/material/Popover';
import {debounce} from 'lodash';

import ReactGA from 'react-ga4';

import {fNumber, fPercent, fShortenNumber} from 'src/utils/format-number';
import {paths} from 'src/routes/paths';
import axios, {endpoints} from '../../utils/axios';
import {AIAnalysisTypesEnum, CompanyDocumentsType, ScoredCompany2} from './types';
import NavSectionVertical from '../nav-section/vertical/nav-section-vertical';
import PriceChart from './price-chart';
import {ScoreData} from './score-data';
import CompanyFinancials from './financials';
import QuickRatios from './quick-ratios';
import {defaultGrowthScore, shouldUseLocalLogo} from './constants';
import CompanyPublicDocuments from './company-public-documents';
import AIAnalysis from './a-i-analysis';
import CompanyValuation from './company-valuation';
import {getCurrencySymbol, scrollToElement} from './utils';
import CompanyBusiness from './company-business';
import {LoginToAccess} from '../utility_components/login-to-access';
import {BecomePremiumAccess} from '../utility_components/become-premium-access';
import CompanyGrowth from './company-growth';
import CompanyFinancialStrength from './company-financial-strength';
import EarningsCallAiAnalysis from './earnings-call-ai-analysis';
import NewsAnalysis from './news_analysis';
import Iconify from '../iconify';
import {gaTimingEvent} from '../../utils/ga';
import LoginRegisterDialog from './login-register-dialog';
import {useAuthContext} from '../../auth/hooks';
import {addToVisitedCompanies, getVisitedCompanies, setVisitedCompaniesToTwo,} from '../../utils/stogare-utils';


export default function CompanyDetail() {
  ReactGA.send({hitType: 'pageview', page: window.location.pathname, title: 'Company Detail'});
  const {authenticated, initialize} = useAuthContext();


  const {ticker} = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  ReactGA.event('CompanyView', {
    category: 'Company',
    action: 'View details for company',
    label: ticker,
  });
  const defaultCompany = {
    company_name: '',
    ticker: '',
    sector: '',
    industry: '',
    market_cap: 0,
    price: 0,
    description: '',
    image_url: '',
    score: 0,
    score_data: {
      price_data: {
        score: 0,
        price_to_earnings: 0,
        price_to_book: 0,
        price_to_free_cashflow: 0,
        ev_to_ebitda: 0,
        peg: 0,
      },
      solvency_data: {
        score: 0,
        current_ratio: 0,
        quick_ratio: 0,
        debt_to_equity: 0,
        debt_to_assets: 0,
        interest_coverage_ratio: 0,
        piotroski_score: 0,
      },
      efficiency_data: {
        score: 0,
        return_on_capital_employed: 0,
        return_on_equity: 0,
        return_on_assets: 0,
        operating_profit_margin: 0,
        net_profit_margin: 0,
        asset_turnover: 0,
      },
      growth_data: {
        score: 0,
        revenue_1y_expected_change: 0,
        ebitda_1y_expected_change: 0,
        eps_1y_expected_change: 0,
        revenue_5y_actual_change: 0,
        ebitda_5y_actual_change: 0,
        eps_5y_actual_change: 0,
        revenue_growth_consistency: 0,
        ebitda_growth_consistency: 0,
        eps_growth_consistency: 0,
      },
    },
    prices: [],
    country: '',
    currency: '',
    website: '',
    full_time_employees: 0,
    ceo: '',
    quick_numbers: {
      valuation: {
        price_earnings_ratio: 0,
        price_to_book_ratio: 0,
        price_to_sales_ratio: 0,
        price_to_free_cash_flows_ratio: 0,
        price_to_operating_cash_flows_ratio: 0,
        ev_to_ebitda: 0,
        price_to_earnings_growth_ratio: 0,
      },
      solvency: {
        debt_to_equity_ratio: 0,
        interest_coverage_ratio: 0,
        cashflow_to_debt_ratio: 0,
        debt_ratio: 0,
        long_term_debt_to_capitalization_ratio: 0,
        current_ratio: 0,
        cash_ratio: 0,
      },
      efficiency: {
        return_on_equity_ratio: 0,
        return_on_capital_employed: 0,
        operating_profit_margin: 0,
        net_profit_margin: 0,
        asset_turnover_ratio: 0,
        inventory_turnover_ratio: 0,
        receivables_turnover_ratio: 0,
      },
      growth: {
        revenue_growth: 0,
        net_income_growth: 0,
        operating_cashflow_growth: 0,
        five_y_revenue_growth: 0,
        five_y_net_income_growth: 0,
        expected_revenue_growth_quarter: 0,
        expected_revenue_growth_year: 0,
      },
      dividend_yield_percentage: 0,
    },
  };

  const [company, setCompany] = useState<ScoredCompany2>(defaultCompany as ScoredCompany2);
  const [documents, setCompanyDocuments] = useState<CompanyDocumentsType | null>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const handleSignUpClick = () => {
    ReactGA.event('AI SignUpClick', {
      category: 'New Feature Sign up',
      action: 'Click',
      label: 'AI Analysis',
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event: any, popContent: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(popContent);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };
  const hoverStyles = {
    '&:hover': {
      cursor: 'help',
    },
  };

  const [visitedCompanies, setVisitedCompanies] = useState(getVisitedCompanies());
  const VISITED_COMPANIES_LIMIT = 3;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!ticker)
      return;

    addToVisitedCompanies(ticker);
    setVisitedCompanies(getVisitedCompanies());
  }, [ticker]);

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        setLoading(true);
        const result = await axios.get<any>(`${endpoints.companies.get}/${ticker}`);
        setCompany(result.data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      } finally {
        setLoading(false);
      }
    };

    const getCompanyDocuments = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.companies.documents}/${ticker}`);
        setCompanyDocuments(result.data);
      } catch (error) {
        console.error('Error fetching company documents:', error);
      }
    };

    getCompanyData();
    getCompanyDocuments();
  }, [ticker]);

  useEffect(() => {
    ReactGA.event('CompanySectionClick', {
      category: 'Company',
      action: 'Click',
      label: location.hash,
    });

    scrollToElement(location);
  }, [location]);

  const [currentSection, setCurrentSection] = useState('company-info');
  const sectionEnterTimeRef = useRef(Date.now());

  const NAV_ITEMS = useMemo(() => [{
    subheader: '',
    items: [
      {title: 'Overview', path: '#company-info'},
      {title: 'Scoring', path: '#scoring'},
      {title: 'Main Ratios', path: '#main-ratios'},
      {title: 'News', path: '#news-summary'},
      {title: 'Valuation', path: '#valuation'},
      {title: 'Business Model', path: '#business-model'},
      {title: 'Growth', path: '#growth'},
      {title: 'Financial Strength', path: '#financial-strength'},
      {title: 'Earnings Call Insights', path: '#earnings-call'},
      {title: 'Annual & Quarterly Reports', path: '#annual-quarterly-reports'},
      {title: 'Financials', path: '#financials'},
    ],
  }], []);

  // Debounced handleScroll function
  const handleScroll = debounce(() => {
    let currentSectionId = '';
    console.log('Scrolling...');

    // calculate the offsetTop and offsetHeight of each section
    const sectionOffsets = NAV_ITEMS[0].items.map(item => {
      const elementId = item.path.substring(1); // Remove '#' from path to get ID
      const element = document.getElementById(elementId); // Remove '#' from path to get ID
      // get element's parent element with className = item.path.substring(1) to get the height of the parent
      const parent = document.getElementsByClassName(elementId)[0];
      return {
        id: item.path.substring(1),
        offsetTop: element ? element.offsetTop : 0,
        // height is the height of the parent element
        offsetHeight: parent ? parent.clientHeight : 0,
      };
    });

    // get the current scroll position
    const scrollPosition = window.scrollY + window.innerHeight / 2; // Adjust as needed

    // find the current section id
    // eslint-disable-next-line no-restricted-syntax
    for (const section of sectionOffsets) {
      if (scrollPosition >= section.offsetTop && scrollPosition <= section.offsetTop + section.offsetHeight) {
        currentSectionId = section.id;
        break;
      }
    }

    // if the current section id is different from the previous one, send a timing event
    if (currentSectionId && currentSectionId !== currentSection) {
      const timeSpentSec = Math.round((Date.now() - sectionEnterTimeRef.current) / 1000);
      sectionEnterTimeRef.current = Date.now();
      setCurrentSection(currentSectionId);
      gaTimingEvent(currentSection, timeSpentSec);
    }
  }, 1000); // Debounce delay of 1000ms


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel(); // Cancel any pending debounced calls on cleanup
    };
  }, [NAV_ITEMS, handleScroll]);


  const defaultConfig = {
    itemGap: 4,
    iconSize: 24,
    currentRole: 'admin',
    itemRootHeight: 44,
    itemSubHeight: 36,
    itemPadding: '4px 8px 4px 12px',
    itemRadius: 8,
    hiddenLabel: false,
  };

  const overall_score = company.score ? parseFloat(fShortenNumber(company.score)) : 0;
  const price_score = company.score_data?.price_data.score ? parseFloat(fShortenNumber(company.score_data?.price_data.score)) : 0;
  const solvency_score = company.score_data?.solvency_data.score ? parseFloat(fShortenNumber(company.score_data?.solvency_data.score)) : 0;
  const growth_score = company.score_data?.growth_data?.score ? parseFloat(fShortenNumber(company.score_data?.growth_data.score)) : defaultGrowthScore;
  const efficiency_score = company.score_data?.efficiency_data.score ? parseFloat(fShortenNumber(company.score_data?.efficiency_data.score)) : 0;
  const quick_numbers = {
    valuation: [
      {
        key: 'P/E Ratio',
        value: company.quick_numbers.valuation.price_earnings_ratio,
        formatter: fShortenNumber,
        helpText: 'Indicates how much investors are paying for a dollar of earnings. Higher values suggest overvaluation or high growth expectations, while lower values may indicate undervaluation.',
      },
      {
        key: 'P/B Ratio',
        value: company.quick_numbers.valuation.price_to_book_ratio,
        formatter: fShortenNumber,
        helpText: 'Compares a company\'s market value to its book value. Lower ratios suggest undervaluation, while higher ratios could indicate overvaluation or strong future growth.',
      },
      {
        key: 'P/S Ratio',
        value: company.quick_numbers.valuation.price_to_sales_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows how much investors are paying for a dollar of sales. Lower ratios are typically better, indicating undervaluation, while higher ratios suggest overvaluation.',

      },
      {
        key: 'P/FCF Ratio',
        value: company.quick_numbers.valuation.price_to_free_cash_flows_ratio,
        formatter: fShortenNumber,
        helpText: 'Compares the company\'s market price to its free cash flow. Lower ratios may indicate the stock is undervalued relative to its cash generation ability.',

      },
      {
        key: 'EV/EBITDA',
        value: company.quick_numbers.valuation.ev_to_ebitda,
        formatter: fShortenNumber,
        helpText: 'Evaluates a company\'s value, including debt, relative to its core earnings. Lower ratios suggest undervaluation, while higher ratios can indicate overvaluation.',

      },
      {
        key: 'PEG Ratio',
        value: company.quick_numbers.valuation.price_to_earnings_growth_ratio,
        formatter: fShortenNumber,
        helpText: 'Links P/E ratio with earnings growth rate. Lower values are better, suggesting undervaluation based on growth, while higher values may indicate overvaluation.',

      },
      {
        key: 'Dividend Yield',
        value: company.quick_numbers.dividend_yield_percentage,
        formatter: fPercent,
        helpText: 'Shows the dividend payout relative to the stock price. Higher yields can be attractive for income, but sustainability of dividends is key.',

      },
    ],
    solvency: [
      {
        key: 'Debt/Equity',
        value: company.quick_numbers.solvency.debt_to_equity_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows the proportion of equity and debt used to finance a company\'s assets. Lower values suggest less reliance on debt, while higher values may indicate financial risk.',
      },
      {
        key: 'Interest Coverage Ratio',
        value: company.quick_numbers.solvency.interest_coverage_ratio,
        formatter: fShortenNumber,
        helpText: 'Indicates a company\'s ability to meet interest payments on its debt. Higher values suggest lower risk of default, while lower values may indicate financial stress. Value of at least 1.5 is desirable.',
      },
      {
        key: 'Cashflow/Debt',
        value: company.quick_numbers.solvency.cashflow_to_debt_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows a company\'s ability to pay off its debt using its operating cash flow. Higher values suggest lower risk of default, while lower values may indicate financial stress.',
      },
      {
        key: 'Debt Ratio',
        value: company.quick_numbers.solvency.debt_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows the proportion of a company\'s assets financed by debt. Lower values suggest less reliance on debt, while higher values may indicate financial risk.',
      },
      {
        key: 'Long Term Debt/Capitalization',
        value: company.quick_numbers.solvency.long_term_debt_to_capitalization_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows the proportion of a company\'s long-term debt relative to its total capitalization. Lower values suggest less reliance on debt, while higher values may indicate financial risk.',
      },
      {
        key: 'Current Ratio',
        value: company.quick_numbers.solvency.current_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows a company\'s ability to pay short-term obligations with its short-term assets. Higher values suggest lower risk of default, while lower values may indicate financial stress.',
      },
      {
        key: 'Cash Ratio',
        value: company.quick_numbers.solvency.cash_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows a company\'s ability to pay short-term obligations with its cash and cash equivalents. Higher values suggest lower risk of default, while lower values may indicate financial stress.',
      },
    ],
    efficiency: [
      {
        key: 'Return on Equity',
        value: company.quick_numbers.efficiency.return_on_equity_ratio * 100,
        formatter: fPercent,
        helpText: 'Shows how much profit a company generates with the money shareholders have invested. Higher values suggest better performance.',
      },
      {
        key: 'Return on Capital Employed',
        value: company.quick_numbers.efficiency.return_on_capital_employed * 100,
        formatter: fPercent,
        helpText: 'Shows how much profit a company generates with the money invested in its operations. Higher values suggest better performance.',
      },
      {
        key: 'Operating Profit Margin',
        value: company.quick_numbers.efficiency.operating_profit_margin * 100,
        formatter: fPercent,
        helpText: 'Shows how much profit a company makes from its operations. Higher values suggest better performance.',
      },
      {
        key: 'Net Profit Margin',
        value: company.quick_numbers.efficiency.net_profit_margin * 100,
        formatter: fPercent,
        helpText: 'Shows how much profit a company makes from its total revenue. Higher values suggest better performance.',
      },
      {
        key: 'Asset Turnover Ratio',
        value: company.quick_numbers.efficiency.asset_turnover_ratio * 100,
        formatter: fShortenNumber,
        helpText: 'Shows how efficiently a company uses its assets to generate sales. Higher values suggest better performance.',
      },
      {
        key: 'Inventory Turnover Ratio',
        value: company.quick_numbers.efficiency.inventory_turnover_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows how many times a company\'s inventory is sold and replaced over a period. Higher values suggest better performance.',
      },
      {
        key: 'Receivables Turnover Ratio',
        value: company.quick_numbers.efficiency.receivables_turnover_ratio,
        formatter: fShortenNumber,
        helpText: 'Shows how many times a company\'s receivables are collected over a period. Higher values suggest better performance.',
      },
    ],
    growth: [
      {
        key: 'Revenue Growth (1Y)',
        value: company.quick_numbers.growth.revenue_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s revenue has grown over the past year. Higher values suggest better performance.',
      },
      {
        key: 'Net Income Growth (1Y)',
        value: company.quick_numbers.growth.net_income_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s net income has grown over the past year. Higher values suggest better performance.',
      },
      {
        key: 'Revenue Growth (5Y)',
        value: company.quick_numbers.growth.five_y_revenue_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s revenue has grown over the past five years. Higher values suggest better performance.',
      },
      {
        key: 'Net Income Growth (5Y)',
        value: company.quick_numbers.growth.five_y_net_income_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s net income has grown over the past five years. Higher values suggest better performance.',
      },
      {
        key: 'Operating Cashflow Growth (1Y)',
        value: company.quick_numbers.growth.operating_cashflow_growth * 100,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s operating cash flow has grown over the past year. Higher values suggest better performance.',
      },
      // changes below are already converted into percent
      {
        key: 'Expected revenue growth (Q)',
        value: company.quick_numbers.growth.expected_revenue_growth_quarter,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s revenue is expected to grow over the next quarter. Higher values suggest better performance.',
      },
      {
        key: 'Expected revenue growth (Y)',
        value: company.quick_numbers.growth.expected_revenue_growth_year,
        formatter: fPercent,
        helpText: 'Shows how much a company\'s revenue is expected to grow over the next year. Higher values suggest better performance.',
      },
    ],
  };

  const radarChartData = {
    categories: ['Valuation', 'Financial Strength', 'Future Growth', 'Efficiency'],
    series: [
      {name: 'Series 1', data: [price_score, solvency_score, growth_score, efficiency_score]},
    ],
  };


  const renderCompanyHeader = (comp: ScoredCompany2) => {

    const path = `../../assets/images/company_logos/${comp.ticker}.png`;
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Stack direction="row"
               justifyContent="space-between"
               alignItems="center"
               spacing={2}
               sx={{width: '100%'}}>
          <Stack direction="row">
            <Box>
              <Box
                component="img"
                sx={{
                  maxHeight: 60,
                  maxWidth: 60,
                }}
                alt="Company logo"
                src={shouldUseLocalLogo.has(comp.ticker) ? path : comp.image_url}
              />
            </Box>
            <Box sx={{ml: '20px'}}>
              <Box>
                <Typography id="company-info" variant="h5" sx={{color: 'text.primary', mb: 1}}>
                  {comp.company_name}
                </Typography>
                <Typography variant="body1" sx={{color: 'text.secondary'}}>
                  ${comp.ticker}
                </Typography>
              </Box>
            </Box>
          </Stack>

          {/* <Box> */}
          {/*  <Iconify icon="line-md:star-filled"  width={36} height={36} style={{ color: '#00AB55' }} /> */}
          {/* </Box> */}

        </Stack>
        <Box sx={{mb: 0}}>
          <Grid container spacing={1} justifyContent="space-around"
                alignItems="center">
            <Grid item xs={8}>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                Sector
              </Typography>
              <Typography className="desktop-sector" variant="body2" sx={{
                display: {
                  xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block',
                },
                color: 'text.primary',
              }}>
                {`${comp.sector} (${comp.industry})`}
              </Typography>
              <Typography className="mobile-sector" variant="body2" sx={{
                display: {
                  xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none',
                },
                color: 'text.primary',
              }}>
                {comp.sector}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                Market Cap
              </Typography>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                {comp.market_cap ? fShortenNumber(comp.market_cap) : '-'}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                CEO
              </Typography>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                {comp.ceo}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                Employees
              </Typography>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                {comp.full_time_employees ? fNumber(comp.full_time_employees) : '-'}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                Website
              </Typography>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                {/* if not comp.website return "-" */}
                {!comp.website ? '-' : ''}

                {/* display website without https:// and truncate trailing chars after the 30th */}
                {comp.website &&
                  <Link href={comp.website} target="_blank" rel="noopener noreferrer"
                        variant="subtitle2">
                    {
                      comp.website.length > 25 ?
                        `${comp.website.replace(/(^\w+:|^)\/\//, '').substring(0, 25)}...`
                        : comp.website.replace(/(^\w+:|^)\/\//, '')
                    }
                  </Link>
                }

              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                Country
              </Typography>

              {/* if not comp.country return "-" */}
              {!comp.country ? '-' : ''}

              {/* display country flag and name */}
              {comp.country && (
                <Typography variant="body2" sx={{
                  color: 'text.primary',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <Iconify icon={`flagpack:${company.country.toLowerCase()}`}
                           sx={{borderRadius: 0.65, width: 28, marginRight: 1}}/>
                  {comp.country}
                </Typography>

              )}

            </Grid>
          </Grid>
        </Box>
        <Box>

          <Box
            sx={{
              maxHeight: '145px', // Adjust this value to fit exactly 5 lines of text
              overflowY: 'auto', // Enable vertical scrolling
              ...customScrollbarStyles,
            }}
          >
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
              {company.description}
            </Typography>
          </Box>

        </Box>
      </Stack>
    );
  };

  const renderPremiumFeatures = () => (
    <>
      <Box className="news-summary" sx={{mb: 3}}>
        <Typography id="news-summary" variant="h5"
                    sx={{color: 'text.primary', mb: 1}}>
          Latest News Summary <Box component="span"
                                   onMouseEnter={(e) => handlePopoverOpen(e, 'The main topics that are discussed in the latest news are summarized below. Click on \'Show News\' to see the actual news articles for this topic. This section is precomputed on a daily basis.')}
                                   onMouseLeave={handlePopoverClose}
                                   sx={{...hoverStyles}}

        >
          <Iconify icon="material-symbols:help" width={25}/>
        </Box>
        </Typography>
        <LoginToAccess returnTo={`/companies/${ticker}`}
                       featureDescription="the latest news analysis"
                       imageUrl="/assets/images/blurred/news.png"
        >
          <BecomePremiumAccess ticker={ticker}
                               returnTo={`/companies/${ticker}`}
                               featureDescription="the latest news analysis"
                               imageUrl="/assets/images/blurred/news.png"

          >
            <NewsAnalysis ticker={ticker}/>
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>
      <Box className="valuation" sx={{mb: 3}}>
        <Stack direction="row" spacing={1}>
          <Typography id="valuation" variant="h5"
                      sx={{color: 'text.primary', pt: '1px', mb: 2}}>
            Valuation <FiberNew/>
          </Typography>
        </Stack>
        <LoginToAccess returnTo={`/companies/${ticker}`}
                       featureDescription="the valuation analysis"
                       imageUrl="/assets/images/blurred/valuation.png"
        >
          <BecomePremiumAccess ticker={ticker}
                               returnTo={`/companies/${ticker}`}
                               featureDescription="the valuation analysis"
                               imageUrl="/assets/images/blurred/valuation.png"
          >
            <CompanyValuation ticker={ticker}
                              currencySymbol={getCurrencySymbol(company.currency)}/>
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>
      <Box className="business-model" sx={{mb: 3}}>
        <Typography id="business-model" variant="h5" sx={{color: 'text.primary', mb: 1}}>
          Business Model <FiberNew/>
        </Typography>
        <LoginToAccess returnTo={`/companies/${ticker}`}
                       featureDescription="the business model analysis"
                       imageUrl="/assets/images/blurred/business-model.png"
        >
          <BecomePremiumAccess ticker={ticker}
                               returnTo={`/companies/${ticker}`}
                               featureDescription="the business model analysis"
                               imageUrl="/assets/images/blurred/business-model.png"
          >
            <CompanyBusiness ticker={ticker}/>
          </BecomePremiumAccess>
        </LoginToAccess>

        {/*
                                    <AIAnalysisMain ticker={ticker} title="Competitive Advantage"
                                  analysisType={AIAnalysisTypesEnum.CompetitiveAdvantage}/>
                  <AIAnalysisMain ticker={ticker} title="Risks"
                                  analysisType={AIAnalysisTypesEnum.Risks}/>
                  <AIAnalysisMain ticker={ticker} title="Management Strategic Plans"
                                  analysisType={AIAnalysisTypesEnum.FutureOutlook}/>
                  <AIAnalysisMain ticker={ticker} title="Share Repurchase"
                                  analysisType={AIAnalysisTypesEnum.ShareRepurchase}/>
                  */}

      </Box>

      <Box className="growth" sx={{mb: 3}}>
        <Typography id="growth" variant="h5" sx={{color: 'text.primary', mb: 1}}>
          Growth <FiberNew/>
        </Typography>
        <LoginToAccess returnTo={`/companies/${ticker}`}
                       featureDescription="the growth analysis"
                       imageUrl="/assets/images/blurred/growth.png"
        >
          <BecomePremiumAccess ticker={ticker}
                               returnTo={`/companies/${ticker}`}
                               featureDescription="the growth analysis"
                               imageUrl="/assets/images/blurred/growth.png"
          >
            <CompanyGrowth ticker={ticker}/>
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>

      <Box className="financial-strength" sx={{mb: 3}}>
        <Typography id="financial-strength" variant="h5" sx={{color: 'text.primary', mb: 1}}>
          Financial Strength <FiberNew/>
        </Typography>
        <LoginToAccess returnTo={`/companies/${ticker}`}
                       featureDescription="the financial strength analysis"
                       imageUrl="/assets/images/blurred/financial-strength.png"
        >
          <BecomePremiumAccess ticker={ticker}
                               returnTo={`/companies/${ticker}`}
                               featureDescription="the financial strength analysis"
                               imageUrl="/assets/images/blurred/financial-strength.png"
          >
            <CompanyFinancialStrength ticker={ticker}/>
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>


      {
        documents?.earnings_call_transcript &&
        (
          <Box className="earnings-call" sx={{mb: 3}}>
            <Typography id="earnings-call" variant="h5"
                        sx={{color: 'text.primary', mb: 1}}>
              Earnings Call Insights<FiberNew/>
            </Typography>
            <LoginToAccess returnTo={`/companies/${ticker}`}
                           featureDescription="the earnings call insights"
                           imageUrl="/assets/images/blurred/earnings-call-insights.png"
            >
              <BecomePremiumAccess ticker={ticker}
                                   returnTo={`/companies/${ticker}`}
                                   featureDescription="the earnings call insights"
                                   imageUrl="/assets/images/blurred/earnings-call-insights.png"

              >
                <EarningsCallAiAnalysis ticker={ticker}
                                        earnings_call_transcript={documents.earnings_call_transcript}/>
              </BecomePremiumAccess>
            </LoginToAccess>
          </Box>
        )
      }
    </>
  );


  const customScrollbarStyles = {
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '8px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.grey[600],
    },
  };

// @ts-ignore
  return (
    company.ticker && !loading ? (
        <Stack sx={{mt: 3}}>
          <Grid key="a" className="companyLayout" container spacing={3} sx={{minHeight: '100vh'}}>
            {/* Display on Desktop */}
            <Grid item xs={2}
                  sx={{
                    bgcolor: 'background.neutral',
                    display: {
                      xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block',
                    },
                    height: '100vh', position: 'sticky', top: 0, zIndex: theme.zIndex.drawer,
                  }}
            >
              <Box>
                <Button onClick={() => navigate(-1)}>
                  <ArrowBackIosIcon/>
                </Button>
              </Box>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{height: '100vh'}}
              >

                <NavSectionVertical
                  data={NAV_ITEMS}
                  config={defaultConfig}
                  sx={{
                    height: '100vh', // make it as tall as the viewport
                    width: '100%',
                    borderRadius: 2,
                    bgcolor: 'background.neutral',
                    // boxShadow: theme.customShadows.z20,
                    overflowY: 'auto', // in case the content is taller than the screen
                  }}
                />
              </Stack>
            </Grid>
            {/* Display on Mobile */}
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} sx={{mt: 1}}>
              <Box sx={{
                display: {xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none'},
                mb: 2,
              }}>
                <Button onClick={() => navigate(-1)}>
                  <ArrowBackIosIcon/>
                </Button>
              </Box>
              <Box className="company-container" sx={{mx: 3}}>
                <Box className="company-info" sx={{mb: 3}}>
                  <Grid container spacing={2} justifyContent="center">

                    <Grid item xs={12} lg={6} xl={6}>
                      {renderCompanyHeader(company)}
                    </Grid>


                    <Grid item className="price-chart" xs={12} lg={6} xl={6}>
                      <Box className="price-chart" sx={{mb: 3}}>
                        <PriceChart currencySymbol={getCurrencySymbol(company.currency)}
                                    prices={company.prices}/>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>


                {company.score && company.score_data ? (
                  <Box className="scoring" sx={{mb: 3}}>
                    <Stack direction="row" spacing={1}>
                      <Typography id="scoring" variant="h5" sx={{color: 'text.primary', mb: 1}}>
                        Scoring
                      </Typography>
                      <Box sx={{position: "relative", width: "100%"}}>
                        <Button
                          size="small"
                          color="primary"
                          sx={{fontSize: "12px", position: "absolute", bottom: 12}}
                          onClick={() => navigate(paths.informational.scoring_algorithm)}
                        >
                          How this works?
                        </Button>
                      </Box>

                    </Stack>
                    <ScoreData overallScore={overall_score} chart={radarChartData}
                               scoreData={company.score_data}/>
                  </Box>
                ) : <Box>No scoring data for this company :(</Box>}

                <Box className="main-ratios" sx={{mb: 3}}>
                  <Stack direction="row" spacing={1}>
                    <Typography id="main-ratios" variant="h5"
                                sx={{color: 'text.primary', pt: '1px', mb: 2}}>
                      Main Ratios
                    </Typography>
                    <AIAnalysis ticker={ticker} analysisType={AIAnalysisTypesEnum.QuickNumbers}/>
                  </Stack>

                  <QuickRatios
                    valuation={quick_numbers.valuation}
                    solvency={quick_numbers.solvency}
                    efficiency={quick_numbers.efficiency}
                    growth={quick_numbers.growth}
                  />
                </Box>
                {renderPremiumFeatures()}
                {
                  documents?.latest_annual_url || documents?.latest_quarterly_url ? (
                    <Box className="annual-quarterly-reports" sx={{mb: 3}}>
                      <Typography id="annual-quarterly-reports" variant="h5"
                                  sx={{color: 'text.primary', mb: 1}}>
                        Annual And Quarterly Reports
                      </Typography>
                      <CompanyPublicDocuments
                        ticker={ticker}
                        latest_annual_url={documents.latest_annual_url}
                        latest_quarterly_url={documents.latest_quarterly_url}
                      />
                    </Box>
                  ) : null
                }
                <CompanyFinancials ticker={company.ticker}/>
              </Box>
            </Grid>
          </Grid>
          <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
            onClose={handlePopoverClose}
            disableRestoreFocus
            disableScrollLock
            sx={{pointerEvents: 'none'}}
            slotProps={{
              paper: {style: {width: 250}},
            }}
          >
            <Typography variant="body2" sx={{p: 0.5, fontSize: 12}}>
              {popoverContent}
            </Typography>
          </Popover>
          <LoginRegisterDialog
            dialogOpen={!authenticated && visitedCompanies.length >= VISITED_COMPANIES_LIMIT}
            onClose={() => {
              setVisitedCompaniesToTwo();
              setVisitedCompanies(getVisitedCompanies());
            }}
          />
        </Stack>
      ) :
      (
        <Box>
          <LinearProgress/>
        </Box>
      )
  );
}
