import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import Iconify from "../iconify";
import {shouldUseLocalLogo} from "../company/constants";
import {ScoredCompany2} from "../company/types";


export default function CompanyHeader({company}: {
  company: ScoredCompany2 | null
}) {
  if (!company) {
    return null;
  }

  const path = `../../assets/images/company_logos/${company.ticker}.png`;
  return (
    <Stack direction="row"
           spacing={2}
           sx={{width: '100%'}}
    >
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box>
          <Box
            component="img"
            sx={{
              maxHeight: 60,
              maxWidth: 60,
            }}
            alt="Company logo"
            src={shouldUseLocalLogo.has(company.ticker) ? path : company.image_url}
          />
        </Box>
        <Box sx={{ml: '20px'}}>
          <Box>
            <Typography id="company-info" variant="h5" sx={{color: 'text.primary', mb: 1}}>
              {company.company_name}
            </Typography>
            <Typography variant="body1" sx={{color: 'text.secondary'}}>
              ${company.ticker}
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Box>

        <Button
          color="primary"
          size="small"
          variant="text"
          sx={{borderColor: 'text.primary'}}
          startIcon={<Iconify icon="eva:external-link-fill" width={16}/>}
          component={Link}
          to={`/companies/${company.ticker}`}
          target="_blank"

        >
          View Analysis
        </Button>
      </Box>

    </Stack>)
}
