// React
import React, {useEffect, useState} from 'react';
// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip
} from "@mui/material";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Link, useNavigate} from "react-router-dom";

// constants
import {sectorsWithIcons} from 'src/layouts/features/constants';
import {marketCapFormatter} from 'src/utils/formatting';
import axios, {endpoints} from '../../utils/axios';
import Iconify from '../iconify';
import ScoreBreakdownProgressElement from './score-breakdown-progress-element';
import AiProgressbar from "../utility_components/ai-progressbar";
import PriceChartMinimal from "../price-chart/price-chart-minimal";
import PercentageChange from "../price-chart/percentage-change";
import {shouldUseLocalLogo} from "./constants";
import {gaTimingEvent} from "../../utils/ga";
import {paths} from "../../routes/paths";


interface TopCompany {
  symbol: string;
  companyName: string;
  marketCap: number;
  sector: string;
  price: number;
  country: string;
  score: number;
  logo_url: string;
  priceLast3Months: any;
}

interface TopCompaniesResponse {
  date: string;
  all_sectors: TopCompany[];
  financial_services: TopCompany[];
  technology: TopCompany[];
  consumer_cyclical: TopCompany[];
  healthcare: TopCompany[];
  industrials: TopCompany[];
  basic_materials: TopCompany[];
  real_estate: TopCompany[];
  consumer_defensive: TopCompany[];
  utilities: TopCompany[];
  communication_services: TopCompany[];
  energy: TopCompany[];
}

// add title_snake_case
const sectors = [...sectorsWithIcons.map(sector => ({
  title: sector.title,
  title_snake_case: sector.title.toLowerCase().replace(' ', '_'),
  icon: sector.icon,
}))];
// add all_sectors
sectors.unshift({
  title: 'All Sectors',
  title_snake_case: 'all_sectors',
  icon: "hugeicons:medal-first-place"
});

const tableFontSize = "0.8rem";

export default function TopCompanies() {
  const [topCompanies, setTopCompanies] = useState<TopCompaniesResponse | null>(null);
  const [currentSector, setCurrentSector] = useState<string>('all_sectors');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentSector(newValue);
  };
  const handleSelectChange = (event: SelectChangeEvent) => {
    setCurrentSector(event.target.value);
  };
  // track time spent
  useEffect(() => {
    const startTime = Date.now(); // Record start time

    return () => {
      const endTime = Date.now(); // Record end time
      const timeSpentMs = endTime - startTime; // Calculate time spent in milliseconds
      const timeSpentSec = Math.round(timeSpentMs / 1000); // Convert to seconds

      gaTimingEvent("Top Companies", timeSpentSec)
    };
  }, []);

  useEffect(() => {
    // get companies for selected sectors and sliders
    const getTopCompanies = async () => {
      try {
        setLoading(true);
        const result = await axios.get<any>(`${endpoints.dashboard.top_companies}`);
        setTopCompanies(result.data);
      } catch (error) {
        console.error("Error fetching top companies:", error);
      } finally {
        setLoading(false);
      }
    };
    getTopCompanies();
  }, []);

  const renderPrice = (company: any, mobile: boolean = false) => {
    if (company.priceLast3Months.length <= 0)
      return <Typography sx={{fontSize: tableFontSize}}>${`${company.price.toFixed(2)}`}</Typography>

    const mostRecentPrice = company.priceLast3Months[0][1];
    const leastRecentPrice = company.priceLast3Months[company.priceLast3Months.length - 1][1];
    if (mobile) {
      return (
        <Stack direction="row" spacing={1}>
          <Typography fontWeight="bold"
                      sx={{fontSize: tableFontSize}}>${`${mostRecentPrice.toFixed(2)}`}</Typography>
          <PercentageChange leastRecentPrice={leastRecentPrice} mostRecentPrice={mostRecentPrice}/>
        </Stack>
      )
    }
    return (
      <Stack direction="column">
        <Typography sx={{fontSize: tableFontSize}}>${`${mostRecentPrice.toFixed(2)}`}</Typography>
        <PercentageChange leastRecentPrice={leastRecentPrice} mostRecentPrice={mostRecentPrice}/>
      </Stack>
    )
  }
  const renderDesktopTable = () => (
    <Box sx={{mt: 2, mb: 2, width: '100%'}}>
      <TableContainer component={Paper}>
        <Table
          sx={{fontSize: 10, '& .MuiTableCell-root': {fontSize: tableFontSize}}}>
          <TableHead>
            <TableRow>
              <TableCell align="center"/>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Country</TableCell>
              <TableCell align="center">Last 3 Months</TableCell>
              <TableCell align="center">Price</TableCell>
              {currentSector === 'all_sectors' && <TableCell align="center">Sector</TableCell>}
              <TableCell align="center">Score</TableCell>
              <TableCell align="center">Market Cap</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* @ts-ignore */}
            {topCompanies && topCompanies[currentSector].map((company, index) => (
              <TableRow
                key={index}
                component={Link}
                to={`/companies/${company.symbol}`}
                sx={{
                  '&:last-child td, &:last-child th': {border: 0},
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: theme.palette.action.hover,
                  },
                  textDecoration: 'none',
                  color: 'inherit'
                }}
              >
                <TableCell align="center">
                  <Box component="img" src={company.logo_url} sx={{width: 40, height: 40}}/>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{fontSize: tableFontSize}}>{company.companyName}</Typography>
                  <Typography sx={{
                    color: 'text.secondary',
                    fontSize: tableFontSize,
                  }}>{company.symbol}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={company.country}>
                    <Box component="span">
                      <Iconify icon={`flagpack:${company.country.toLowerCase()}`}
                               sx={{borderRadius: 0.65, width: 28}}/>
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell align="center" sx={{p: 0, height: 110}}>
                  <PriceChartMinimal prices={company.priceLast3Months}/>
                </TableCell>

                <TableCell align="center">
                  {renderPrice(company)}
                </TableCell>

                {currentSector === 'all_sectors' &&
                  <TableCell align="center">{company.sector}</TableCell>}
                <TableCell align="center">
                  <Tooltip
                    title={
                      <>
                        <Typography variant="body2"
                                    sx={{fontSize: 12}}>Score: {company.score.toFixed(2)} /
                          5</Typography>
                        <Typography variant="body2" sx={{fontSize: 12}}>Valuation
                          Score: {company.score_data?.price_data?.score.toFixed(2)} / 5</Typography>
                        <Typography variant="body2" sx={{fontSize: 12}}>Financial Strength
                          Score: {company.score_data?.solvency_data?.score.toFixed(2)} /
                          5</Typography>
                        <Typography variant="body2" sx={{fontSize: 12}}>Efficiency
                          Score: {company.score_data?.efficiency_data?.score.toFixed(2)} /
                          5</Typography>
                        <Typography variant="body2" sx={{fontSize: 12}}>Growth
                          Score: {company.score_data?.growth_data?.score.toFixed(2)} / 5</Typography>
                      </>
                    }
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.1}>
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <ScoreBreakdownProgressElement score={company.score_data.price_data?.score}
                                                       widthParam="8px" heightParam="32px" isVertical
                                                       sx={{mx: 0.2}}/>
                        <Typography sx={{fontSize: 10}}>V</Typography>
                      </Stack>
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <ScoreBreakdownProgressElement
                          score={company.score_data.solvency_data?.score}
                          widthParam="8px" heightParam="32px" isVertical
                          sx={{mx: 0.2}}/>
                        <Typography sx={{fontSize: 10}}>F</Typography>
                      </Stack>
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <ScoreBreakdownProgressElement
                          score={company.score_data.efficiency_data?.score}
                          widthParam="8px" heightParam="32px" isVertical
                          sx={{mx: 0.2}}/>
                        <Typography sx={{fontSize: 10}}>E</Typography>
                      </Stack>
                      <Stack direction="column" alignItems="center" justifyContent="center">
                        <ScoreBreakdownProgressElement score={company.score_data.growth_data?.score}
                                                       widthParam="8px" heightParam="32px" isVertical
                                                       sx={{mx: 0.2}}/>
                        <Typography sx={{fontSize: 10}}>G</Typography>
                      </Stack>

                    </Stack>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">${marketCapFormatter(company.marketCap)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderMobileTable = () => (
    <Stack sx={{mb: 2, width: '100%'}}>
      {/* @ts-ignore */}
      {topCompanies && topCompanies[currentSector] && topCompanies[currentSector].map((company, index) => (
        <Box
          key={index}
          onClick={() => navigate(`/companies/${company.symbol}`)}
          sx={{
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: theme.palette.action.hover,
            },
            backgroundColor: index % 2 === 0 ? theme.palette.background.default : theme.palette.action.selected,
            p: 2,
          }}
        >
          <Stack direction="column">
            {/* logo, name and ticker */}
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Box component="img"
                     src={shouldUseLocalLogo.has(company.symbol) ? `../../assets/images/company_logos/${company.symbol}.png` : company.logo_url}
                     sx={{maxWidth: 40, maxHeight: 40}}/>
                <Stack direction="column" sx={{ml: 1}}>
                  <Typography sx={{fontSize: tableFontSize, fontWeight: 'bold'}}>
                    {company.companyName}
                  </Typography>
                  <Typography sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
                    {company.symbol}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Button
                  color="inherit"
                  variant="text"
                  sx={{borderColor: 'text.primary'}}
                  component={Link}
                  to={`/companies/${company.symbol}`}
                  endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={20}/>}
                >
                  View Company
                </Button>
              </Box>
            </Stack>


            <Grid container spacing={2} sx={{mt: 1}}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
                  Country
                </Typography>

                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Iconify icon={`flagpack:${company.country.toLowerCase()}`}
                           sx={{borderRadius: 0.65, width: 16}}/>
                  <Typography variant="body2" sx={{fontSize: tableFontSize}}>
                    {company.country}
                  </Typography>
                </Stack>
                <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
                  Market Cap
                </Typography>
                <Typography variant="body2"
                            sx={{fontSize: tableFontSize}}>${marketCapFormatter(company.marketCap)}</Typography>
                <Typography variant="body2" sx={{
                  fontSize: tableFontSize,
                  color: 'text.secondary'
                }}> Sector </Typography>
                <Typography variant="body2"
                            sx={{fontSize: tableFontSize}}>{company.sector}</Typography>
                <Typography variant="body2" sx={{
                  fontSize: tableFontSize,
                  color: 'text.secondary',
                  mt: 1
                }}> Price </Typography>
                <Typography variant="body2"
                            sx={{fontSize: tableFontSize}}>{renderPrice(company, true)}</Typography>
                <Typography variant="body2" sx={{
                  fontSize: tableFontSize,
                  color: 'text.secondary'
                }}>Last 3 Months</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>Overall
                  Score</Typography>
                <Typography variant="body2" fontWeight="bold"
                            sx={{fontSize: tableFontSize}}>{company?.score?.toFixed(2)}</Typography>
                <Typography variant="body2" sx={{
                  fontSize: tableFontSize,
                  color: 'text.secondary'
                }}>Breakdown</Typography>

                <Stack direction="column" spacing={0.1}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography sx={{fontSize: 10}}>V</Typography>
                    <ScoreBreakdownProgressElement score={company.score_data?.price_data?.score}
                                                   heightParam="8px" widthParam="40px"
                                                   sx={{mx: 0.2}}/>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography sx={{fontSize: 10}}>F</Typography>
                    <ScoreBreakdownProgressElement score={company.score_data?.solvency_data?.score}
                                                   heightParam="8px" widthParam="40px"
                                                   sx={{mx: 0.2}}/>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography sx={{fontSize: 10}}>E</Typography>
                    <ScoreBreakdownProgressElement score={company.score_data?.efficiency_data?.score}
                                                   heightParam="8px" widthParam="40px"
                                                   sx={{mx: 0.2}}/>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography sx={{fontSize: 10}}>G</Typography>
                    <ScoreBreakdownProgressElement score={company.score_data?.growth_data?.score}
                                                   heightParam="8px" widthParam="40px"
                                                   sx={{mx: 0.2}}/>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="column" alignItems="center" justifyContent="center">
              <PriceChartMinimal prices={company.priceLast3Months} width="100%" height="80%"/>
            </Stack>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
  const renderSectorTabs = () => (
    <Tabs onChange={handleChange} value={currentSector} sx={{width: '100%'}}>
      {sectors.map((sector, index) => (
        <Tab
          key={index}
          icon={<Iconify icon={sector.icon}/>}
          label={`${sector.title}`} value={sector.title_snake_case}
          wrapped
        />
      ))}
    </Tabs>
  )

  const renderSectorSelect = () => (
    <Box sx={{width: '100%'}}>
      <FormControl sx={{mt: 2, minWidth: 200}} size="small">
        <InputLabel key="sectorinput" id="sector-select">Sector</InputLabel>
        <Select
          labelId="sector-select"
          id="demo-simple-select-autowidth"
          value={currentSector}
          onChange={handleSelectChange}
          autoWidth
          label="Sector"
        >
          {sectors.map((sector, index) => (
            <MenuItem key={index} value={sector.title_snake_case}>{sector.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )

  if (loading) {
    return (
      <AiProgressbar text="Top companies loading..."/>
    );
  }

  return (
    <Stack direction="column">
      <Stack direction="row" spacing={1}>
        <Typography variant="h5" sx={{mt: 2}} noWrap>Top Companies Today</Typography>
        <Box sx={{position: "relative"}}>
          <Button
            size="small"
            color="primary"
            sx={{fontSize: "12px", width: "105px", position: "absolute", bottom: 10}}
            onClick={() => navigate(paths.informational.scoring_algorithm)}
          >
            How this works?
          </Button>
        </Box>
      </Stack>
      <Typography variant="body2" sx={{color: "text.secondary"}}>The highest ranked
        companies right now according to our algorithm (updated daily). </Typography>

      {/* Desktop */}
      {!isMobile &&
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
          {renderSectorTabs()}
          {renderDesktopTable()}
        </Stack>}

      {/* Mobile */}
      {isMobile &&
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
          {renderSectorSelect()}
          {renderMobileTable()}
        </Stack>
      }
    </Stack>

  );
};
