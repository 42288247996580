import {Navigate, useRoutes} from 'react-router-dom';

// config
//
import {companyRoutes} from './company';
import {subscriptionsRoutes} from './subscriptions';

import {authRoutes} from './auth';
import NotFoundPage from "../../pages/404";
import MainLayout from "../../layouts/main";
import {HomePage} from "./main";
import {screenerRoutes} from "./screener";
import {dashboardRoutes} from "./dashbaord";
import { analysisRoutes } from './documents_analysis';
import {informationalRoutes} from "./informational";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    // ...dashboardOldRoutes,
    //
    // // Main routes
    // ...mainRoutes,

    // Chat routes
    // ...chatRoutes,

    // Company routes
    ...companyRoutes,

    // Dashboard routes
    ...dashboardRoutes ,

    // Analysis routes
    ...analysisRoutes,

    // Screener routes
    ...screenerRoutes ,

    // Informational routes
    ...informationalRoutes,

    // Subscriptions routes
    ...subscriptionsRoutes,

    {
      path: '/404',
      element: <NotFoundPage/>,
    },
    // No match 404
    {path: '*', element: <Navigate to="/404" replace/>},
  ]);
}
