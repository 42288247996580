import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// @mui
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, {dialogClasses} from '@mui/material/Dialog';
import LinearProgress from "@mui/material/LinearProgress";
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
import {useResponsive} from 'src/hooks/use-responsive';
import {useEventListener} from 'src/hooks/use-event-listener';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {useNavigate} from "react-router-dom";
import SearchNotFound from 'src/components/search-not-found';
//
import ResultItem from './result-item';
import axios, {endpoints} from "../../../utils/axios";


interface CustomSearchbarProps {
  renderSearchButton?: (search: ReturnType<typeof useBoolean>) => React.ReactElement | null;
  handleCompanyClickCustom?: (ticker: string, onDialogClose: () => void) => void;
  country?: string;
  tickersToHide?: string[];
}


function CustomSearchbar({
                           renderSearchButton,
                           handleCompanyClickCustom,
                           country = '',
                           tickersToHide = []
                         }: CustomSearchbarProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const search = useBoolean();
  const mdUp = useResponsive('up', 'md');

  const [searchQuery, setSearchQuery] = useState('');
  const [companyData, setCompanyData] = useState<[]>([]);
  const [showProgress, setShowProgress] = useState(false);
  const debounceTimer = useRef<number | null>(null);

  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery('');
  }, [search]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey && (event.key === 'k' || event.key === 'q')) {
      search.onToggle();
      setSearchQuery('');
    }
  };

  useEventListener('keydown', handleKeyDown);

  const handleClick = useCallback(
    (path: string) => {
      navigate(`/companies/${path}`);
      handleClose();
    },
    [handleClose, navigate]
  );


  const handleSearch = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCompanyData = useCallback(async (q: string) => {
    setShowProgress(true);
    try {
      let url = `${endpoints.companies.search}/${q}`;
      if (country !== '') {
        url = `${endpoints.companies.search}/${q}?country=${country}`;
      }
      const result = await axios.get<any>(url);
      // build list of ItemProps objects
      const data = result.data.map((company: any) => ({
        group: company[2],
        title: company[1],
        path: company[0],
      }));
      setCompanyData(data);
    } catch (error) {
      console.error("Error fetching company search data:", error);
    } finally {
      setShowProgress(false);
    }
  }, [setShowProgress, country])

  useEffect(() => {
    // Clear the existing timer if there is one
    if (debounceTimer.current !== null) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = window.setTimeout(() => {
      getCompanyData(searchQuery);
    }, 500); // 500ms delay

    // Cleanup function to clear the timer if the component unmounts
    // eslint-disable-next-line consistent-return
    return () => {
      if (debounceTimer.current !== null) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [searchQuery, getCompanyData]);

  useEffect(() => {
    if (search.value) {
      getCompanyData('');
    }
  }, [search.value, getCompanyData]);

  const notFound = searchQuery && !companyData.length;

  const renderItems = () => {
    const data = companyData;
    return (
      <List key="groups" disablePadding>
        {data.map((item) => {
          const {title, path, group} = item;

          if (tickersToHide.includes(path)) {
            return null;
          }

          const partsTitle = parse(title, match(title, searchQuery));

          const partsPath = parse(path, match(path, searchQuery));

          return (
            <ResultItem
              path={partsPath}
              title={partsTitle}
              key={`${title}${path}`}
              groupLabel={searchQuery && group}
              onClickItem={() => handleCompanyClickCustom ? handleCompanyClickCustom(path, handleClose) : handleClick(path)}
            />
          );
        })}
      </List>
    )

  };

  const renderButton = () => {
    // Check the platform
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    let shortcutText = isMac ? '(⌘K)' : '(Ctrl+Q)';
    if (!mdUp) {
      shortcutText = '';
    }

    return (
      <Stack direction="row" alignItems="center">
        <Button onClick={search.onTrue}>
          <Iconify icon="eva:search-fill"/>
          {mdUp &&
            <Label sx={{px: 0.75, fontSize: 12, color: 'text.secondary', cursor: 'pointer'}}>Find a
              company {shortcutText}</Label>}
          {!mdUp &&
            <Label sx={{fontSize: 10, color: 'text.secondary', cursor: 'pointer'}}>Search</Label>}

        </Button>
      </Stack>
    );
  };

  return (
    <>
      {renderSearchButton ? renderSearchButton(search) : renderButton()}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{p: 3, borderBottom: `solid 1px ${theme.palette.divider}`}}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start" className="adornment">
                <Iconify icon="eva:search-fill" width={24} sx={{color: 'text.disabled'}}/>
              </InputAdornment>
            }
            endAdornment={<Label sx={{letterSpacing: 1, color: 'text.secondary'}}>esc</Label>}
            inputProps={{
              sx: {typography: 'h6'},
            }}
          />
        </Box>

        <Scrollbar sx={{p: 3, pt: 2, height: 400}}>
          <Box>
            <LinearProgress sx={{display: showProgress ? 'block' : 'none'}}/>
          </Box>
          {notFound ? <SearchNotFound query={searchQuery} sx={{py: 10}}/> : renderItems()}
        </Scrollbar>
      </Dialog>
    </>
  );
}

export default memo(CustomSearchbar);
