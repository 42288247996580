import {ApexOptions} from 'apexcharts';
// @mui
import CardHeader from '@mui/material/CardHeader';
import {Rating, Card, CardProps} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// components
import ScoreRadarStyled from "./score-radar-styled";
import {getProgressBarColor} from "./utils";

// ----------------------------------------------------------------------

interface Props extends CardProps {
  score: number;
  subheader?: string;
  chart: {
    categories: (string | string[])[];
    colors?: string[];
    series: {
      name: string;
      data: number[];
    }[];
    options?: ApexOptions;
  };
}

export default function ScoreRadar({score, subheader, chart, ...other}: Props) {
  const progressColor = getProgressBarColor(score * 20);
  return (
    <Card {...other} sx={{height: '100%', width: '100%'}}>
      <CardHeader
        title={
          <Typography variant="body2" align="center">
            <Stack direction="column" alignItems="center" justifyContent="center">
              <Typography variant="caption" align="center">Overall Score: <strong>{score}/5</strong></Typography>
              <Rating name="overall-score" precision={0.01} value={score}
                      readOnly
                      sx={{
                        '& .MuiRating-iconFilled': {
                          color: progressColor,
                        },
                      }}
              />
            </Stack>
          </Typography>
        }
        subheader={subheader} sx={{mb: 1}}/>
      <ScoreRadarStyled chart={chart}/>
    </Card>
  );
}
