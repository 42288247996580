import React from "react";
import {Box, Button, Typography, useMediaQuery} from '@mui/material';
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import {useTheme} from '@mui/material/styles';
import {useAuthContext} from "../../auth/hooks";
import {paths} from '../../routes/paths';

type Props = {
  children: React.ReactNode;
  ticker: string | undefined;
  returnTo: string;
  featureDescription: string | undefined;
  featureUrl?: string;
  imageUrl?: string;
  showVisitedCompanies?: boolean;
};

export function BecomePremiumAccess({
                                      children,
                                      ticker,
                                      returnTo,
                                      featureDescription = "this feature",
                                      featureUrl = undefined,
                                      imageUrl = undefined,
                                      showVisitedCompanies = true,
                                    }: Props) {
  const {authenticated, user} = useAuthContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const handleMailto = () => {
    window.location.href = 'mailto:contact@valuehunter.net';
  };

  const renderVisitedCompanies = () => (
    user?.user_visited_companies.length > 0 &&
    <Typography variant="body2" align="center" sx={{
      mt: 1, backgroundColor: "rgba(255, 255, 255, 0.3)",
      backdropFilter: "blur(3px)",
    }}>
      You can still access the full analyses of the following
      companies that you have already visited:
      <br/>
      <Box component="span"
           sx={{backgroundColor: "rgba(255, 255, 255, 0.3)", backdropFilter: "blur(3px)",}}>
        {user?.user_visited_companies.map((company: string, index: number) => (

          <Link key={index} href={featureUrl ? `${featureUrl}/${company}`: `/companies/${company}`}>
            {company}
          </Link>
        )).reduce((prev: any, curr: any) => prev === null ? [curr] : [prev, ', ', curr], null)}
      </Box>
    </Typography>
  )

  const renderFreeNoCreditsDesktop = () => (
    <Box
      component="div"
      sx={{
        position: 'relative',
        display: 'inline-block',
        "&:hover .hoverButtons": {
          opacity: 1,
        },
        py: 5,
        px: 10,
        transition: 'transform 0.3s ease-in-out',
        "&:hover": {
          transform: 'scale(1.03)', // Slightly enlarge on hover
        },
      }}
    >
      <Box
        component="img"
        alt="Company logo"
        src={imageUrl}
        sx={{
          width: '100%',
          height: 'auto',
        }}
      />
      <Box
        className="hoverButtons"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0,
          transition: 'opacity 0.3s ease-in-out',
          display: 'flex',
          gap: 2,
        }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="body1" color="text.primary" sx={{
            mt: 1, backgroundColor: "rgba(255, 255, 255, 0.3)",
            backdropFilter: "blur(3px)",
          }}>
            You have run out of credits. Start your free trial to get more credits and full
            access
            to <strong>{featureDescription}</strong>.
          </Typography>

          <Stack direction="row" spacing={2} sx={{mt: 1}}>
            <Button
              variant="contained"
              href={`${paths.main.plans}?returnTo=${returnTo}`}
            >
             ⚡️ Start Free Trial ⚡️
            </Button>
          </Stack>
          {showVisitedCompanies && renderVisitedCompanies()}
        </Stack>

      </Box>
    </Box>
  );


  const renderFreeNoCreditsMobile = () => (
    <Stack direction="column">
      <Typography variant="body2" textAlign="center">
        You have run out of credits. Start your free trial to get more credits and full access
        to <strong>{featureDescription}</strong>.
      </Typography>
      <Stack direction="row" spacing={2} alignSelf="center" sx={{mt: 1}}>
        <Button
          variant="contained"
          href={`${paths.main.plans}?returnTo=${returnTo}`}
        >
          Start Free Trial 🚀
        </Button>
      </Stack>
      {showVisitedCompanies && renderVisitedCompanies()}
    </Stack>
  );

  // user is free and has no credits
  if (
    authenticated &&
    (!user?.subscription || !user.subscription.is_active) &&
    user?.user_credits.credits <= 0 &&
    !user?.user_visited_companies.includes(ticker)
  ) {
    return (
      <Box position="relative" display="flex" justifyContent="center" alignItems="center">
        {!isMobile && renderFreeNoCreditsDesktop()}
        {isMobile && renderFreeNoCreditsMobile()}
      </Box>
    );
  }


  // user is premium but has no credits
  if (
    authenticated &&
    (user?.subscription && user?.subscription.is_active) &&
    user?.user_credits.credits <= 0 &&
    !user?.user_visited_companies.includes(ticker)
  ) {
    return (
      <Stack direction="column">
        {user?.subscription?.status === 'active' && (
          <Typography variant="body2" align="center">
            You have reached your credit limit. Please drop an email to <Link
            onClick={handleMailto} sx={{cursor: "pointer"}}>contact@valuehunter.net</Link> to get
            more
            credits.
          </Typography>
        )}
        {user?.subscription?.status === 'trialing' && (
          <Typography variant="body2" align="center">
            You are currently in <strong>free trial</strong> and you have reached your credit limit.
            Your credits will be renewed after the trial period ends
            on <strong>{new Date(user?.subscription?.next_renewal).toLocaleDateString()}</strong>.
            If you {`can't`} wait drop an email to <Link
            onClick={handleMailto} sx={{cursor: "pointer"}}>contact@valuehunter.net</Link> to get
            more
            credits.
          </Typography>
        )}
        {showVisitedCompanies && renderVisitedCompanies()}
      </Stack>
    );
  }

  return <>{children}</>;
}
