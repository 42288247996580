import React from "react";
import Button from "@mui/material/Button";
import {Collapse} from "@mui/material";

export default function ShowMore({
                                   initialExpanded = false,
                                   expandText = "Show More",
                                   collapseText = "Show Less",
                                   doubleButton = true,
                                   children
                                 }: {
  initialExpanded?: boolean,
  expandText?: string,
  collapseText?: string,
  doubleButton?: boolean,
  children: React.ReactNode
}) {
  const [isExpanded, setIsExpanded] = React.useState(initialExpanded);
  // show children if expanded
  const renderShowButton = () => (
    <Button variant="text" color="primary"
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{fontSize: 11, ml: 1}}
    >
      {isExpanded ? collapseText : expandText}
    </Button>
  )
  return (
    <>
      {doubleButton && renderShowButton()}
      <Collapse in={isExpanded}>
        {isExpanded && children}
        {doubleButton && renderShowButton()}
      </Collapse>
      {!doubleButton && renderShowButton()}

    </>
  )
}
