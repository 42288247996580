import React, {useEffect} from "react";
import {PopoverOrigin} from "@mui/material/Popover";
import {menuItemClasses} from "@mui/material/MenuItem";
import {Box, Button, Popover, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";

import {scrollToElement} from "../company/utils";
import {getPosition} from "../custom-popover/utils";
import {StyledArrow} from "../custom-popover/styles";
import Iconify from "../iconify";

interface Step {
  targetId: string;
  title?: string;
  content: React.ReactNode | string;
  arrowPosition: string; // Use this to define arrow position, e.g. "bottom-left"
  scrollPosition: string | null; // Use this to define scroll position, e.g. "start"
}

export default function Tutorial({steps, storageKey}: {
  steps: Step[],
  storageKey: string,
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [tutorialShown, setTutorialShown] = React.useState(JSON.parse(localStorage.getItem(storageKey) || "false"));
  const [currentStep, setCurrentStep] = React.useState(0);

  const theme = useTheme();

  useEffect(() => {
    if (currentStep < 0 || currentStep >= steps.length) {
      return;
    }

    setTimeout(() => {
      const element = document.getElementById(steps[currentStep].targetId);
      if (element) {
        setAnchorEl(element);
      }
    }, 200); // Small delay ensures the element is in the DOM
  }, [steps, currentStep]);

  if (tutorialShown) {
    return null;
  }

  const nextStep = () => {
    const newStep = currentStep + 1;
    setAnchorEl(null);
    if (newStep < steps.length) {
      if (steps[newStep].scrollPosition) {
        // @ts-ignore
        scrollToElement({hash: steps[newStep].targetId}, steps[newStep].scrollPosition);
      }
      setTimeout(() => {
        setCurrentStep(newStep);
      }, 300);
    }
  };

  const previousStep = () => {
    const newStep = currentStep - 1;
    setAnchorEl(null);
    if (newStep >= 0) {
      if (steps[newStep].scrollPosition) {
        // @ts-ignore
        scrollToElement({hash: steps[newStep].targetId}, steps[newStep].scrollPosition);
      }
      setTimeout(() => {
        setCurrentStep(newStep);
      }, 300);
    }
  };

  const finishTutorial = () => {
    setAnchorEl(null);
    localStorage.setItem(storageKey, JSON.stringify(true));
    setTutorialShown(true);
  }

  // Use the arrow position of the current step to dynamically set the arrow and origins
  const arrow = steps[currentStep].arrowPosition;
  const {style, anchorOrigin, transformOrigin} = getPosition(arrow);

  const open = Boolean(anchorEl);
  const id = open ? "tutorial-popover" : undefined;

  if (!open || currentStep < 0 || currentStep >= steps.length) {
    return null;
  }

  return (
    <Popover
      id={id}
      open={open && currentStep >= 0 && currentStep < steps.length}
      anchorEl={anchorEl}
      disableAutoFocus
      disableEnforceFocus
      anchorOrigin={anchorOrigin as PopoverOrigin}
      transformOrigin={transformOrigin as PopoverOrigin}
      slotProps={{
        paper: {
          sx: {
            width: '320px',
            overflow: 'inherit',
            backgroundColor: 'background.default',
            border: `2px solid ${theme.palette.divider}`,
            ...style,
            [`& .${menuItemClasses.root}`]: {
              '& svg': {
                mr: 2,
                flexShrink: 0,
              },
            },
          },
        },
      }}
    >
      {/* @ts-ignore */}
      <StyledArrow arrow={arrow} borderStyle={`2px solid ${theme.palette.divider}`}/>
      <Stack direction="column" spacing={1} sx={{p: 2}}>
        <Stack direction="row" justifyContent="space-between">
          {steps[currentStep].title &&
            <Typography variant="body2" fontWeight="bold">{steps[currentStep].title}</Typography>}
          <Box/>
          <Typography variant="caption" color="text.secondary"
                      alignItems="end">{`${currentStep + 1}/${steps.length}`}</Typography>

        </Stack>
        {steps[currentStep].content}
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Stack direction="row" justifyContent="center" alignItems="flex-end">
            <Button variant="text" size="small" onClick={() => finishTutorial()}>Skip</Button>
          </Stack>
          <Stack direction="row" justifyContent="right" spacing={1}>
            <Button variant="outlined" onClick={() => previousStep()}
                    disabled={currentStep === 0}>Back</Button>
            {currentStep === steps.length - 1 &&
              <Button variant="contained" onClick={() => finishTutorial()}>Finish!</Button>}
            {currentStep < steps.length - 1 &&
              <Button
                endIcon={<Iconify icon="mingcute:right-fill"/>}
                variant="contained" onClick={() => nextStep()}>Next</Button>}
          </Stack>
        </Stack>
      </Stack>
    </Popover>
  );
}
