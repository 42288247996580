import {Helmet} from "react-helmet-async";
// layouts
import {RouteWithHeader} from "../../layouts/custom-header/route-with-header";
import HowScoringWorks from "../../sections/informational/how-scoring-works";


export const informationalRoutes = [
  {
    path: 'how-scoring-works',
    element: (
      <>
        <Helmet>
          <title>ValueHunter: How Scoring Works?</title>
        </Helmet>
        <RouteWithHeader>
          <HowScoringWorks/>
        </RouteWithHeader>
      </>
    ),
  },
];
