import React from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Iconify from "../iconify";

export default function ReadingTime({timeInMinsAnalysis, timeInMinsReal}: {
    timeInMinsAnalysis: number,
    timeInMinsReal: number
}) {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    if (timeInMinsReal - timeInMinsAnalysis <= 0) {
        // don't show if no time is saved
        return null;
    }

    return (
        <>
            <Button
                variant="text"
                color="info"
                sx={{
                    fontSize: 11, ml: 1,
                    '&:hover': {
                        cursor: 'help',
                        backgroundColor: 'transparent',
                    },
                }}
                startIcon={<Iconify icon="mdi:timer-sand"/>}
                onMouseEnter={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpen(true);
                }}
                onMouseLeave={() => {
                    setOpen(false);
                    setAnchorEl(null);
                }}
            >
                {timeInMinsReal - timeInMinsAnalysis} minutes saved
            </Button>
            <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
                onClose={() => {
                    setOpen(false);
                    setAnchorEl(null);
                }}
                disableRestoreFocus
                disableScrollLock
                sx={{pointerEvents: 'none'}}
                slotProps={{
                    paper: {style: {width: 250}},
                }}
            >
                <Typography variant="body2" sx={{p: 0.5, fontSize: 12}}>
                    Reading the analysis of this section will take
                    you <strong>{timeInMinsAnalysis} minutes</strong> while reading the
                    whole section in the real document will take you <strong>{timeInMinsReal} minutes</strong>.
                </Typography>
            </Popover>
        </>

    )
}
