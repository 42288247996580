import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Iconify from '../iconify';
import {GuidanceType, SummaryType} from './types';
import EarningsQuote from "./earnings_quote";
import ExpandableTitle from "./expandable_title";
import Label from "../label"; // Assuming types are extracted into a common file

export default function RenderSummary({summaryWithTakeaways, paragraphs, onQuoteClick}: {
  summaryWithTakeaways: SummaryType
  paragraphs: string[]
  onQuoteClick: (quote: string) => void
}) {
  const theme = useTheme();

  const getLabelElement = (label: string) => {
    switch (label) {
      case "good":
        return <Label color="success"> Good </Label>
      case "bad":
        return <Label color="error"> Bad </ Label>
      case "neutral":
        return <Label color="info"> Neutral </ Label>
      default:
        return <Label color="info"> Neutral </ Label>
    }
  }
  return (
    <Box sx={{mt: 3}}>
      <Typography variant="h4">Summary</Typography>
      {summaryWithTakeaways.answer.takeaways.sort((a, b) => {
        const labelOrder = {"good": 1, "bad": 2, "neutral": 3};
        return labelOrder[a.label] - labelOrder[b.label];
      }).map((takeaway, index) => {
          // Check if the quote is found in the document paragraphs
          const isQuoteInDocument = paragraphs.some(paragraph => paragraph.toLowerCase().includes(takeaway.quote_from_document.slice(0, -1).toLowerCase()));

          return (
            <React.Fragment key={index}>
              <Stack direction="row" spacing={0.5} sx={{mt: 1}}>
                <Box sx={{minWidth: "65px"}}>
                  {getLabelElement(takeaway.label)}
                </Box>
                <Typography variant="body2">{takeaway.takeaway}</Typography>
                <EarningsQuote quote={takeaway.quote_from_document} onQuoteClick={onQuoteClick}
                               isQuoteInDocument={isQuoteInDocument}/>
              </Stack>
            </React.Fragment>
          );
        }
      )
      }
    </Box>
  );
};
