import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";


import React from "react";
import Iconify from "../iconify";
import Label from "../label";

export default function JoinDiscord({singleLine = false}: { singleLine?: boolean }) {

  const handleDiscord = () => {
    window.open(process.env.REACT_APP_DISCORD_INVITATION, "_blank");
  };

  if (singleLine) {
    return (
      <MenuItem onClick={handleDiscord}>
        <Stack
          direction="row"
          sx={{
            color: "text.secondary",
            // make it look like a button on hover
            "&:hover": {cursor: "pointer", color: "text.primary"},
          }}>
          <Iconify icon="skill-icons:discord" width={24}/>
          <Label color="info" sx={{cursor: "pointer", position: "center", bottom: 15}}>Join our Discord!</Label>
        </Stack>
      </MenuItem>);
  }

  return (
    <Stack direction="row" alignItems="center" spacing={3} sx={{height: '100%', my: {xs: 1, sm: 2}}}>
      <Button
        onClick={handleDiscord}
        sx={{width: '100%', cursor: 'pointer', color: 'text.secondary'}}
        startIcon={<Iconify icon="skill-icons:discord" width={24}/>}>
        <Stack spacing={0}>
          <Typography variant="caption">Join our</Typography>
          <Typography variant="caption">Discord!</Typography>
        </Stack>
      </Button>
    </Stack>);
}
