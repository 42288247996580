import Typography from "@mui/material/Typography";
import React from "react";

export const stepsDesktop = [
  {
    targetId: 'search-button',
    content: (
      <Typography variant='body2'>Select a company to generate the annual report analysis.<br/><br/>
        <strong>Note:</strong> only US traded companies are supported for now.</Typography>
    ),
    arrowPosition: 'top-left',
    scrollPosition: 'start',
  },
  {
    targetId: 'section-navigation',
    content: <Typography variant='body2'>Switch back and forth between the sections of the <strong>annual
      report</strong>.</Typography>,
    arrowPosition: 'bottom-left',
    scrollPosition: 'center',
  },
  {
    targetId: 'summary',
    content: <Typography variant='body2'>Check quickly the most
      important <strong>Strengths</strong> and <strong>Concerns</strong> for each section.</Typography>,
    arrowPosition: 'bottom-left',
    scrollPosition: 'center',
  },
  {
    targetId: 'side-by-side',
    content: <Typography variant='body2'>Find the answers of the most important questions on
      the <strong>left</strong>, deep-dive in the actual document on the <strong>right</strong>.</Typography>,
    arrowPosition: 'bottom-left',
    scrollPosition: 'center',
  },
  {
    targetId: 'first-question-sources',
    content: <Typography variant='body2'>See which parts of the document are relevant to this question. <strong>Click
      on any number</strong> and it will lead you to the <strong>exact location</strong> in the
      document.</Typography>,
    arrowPosition: 'bottom-left',
    scrollPosition: 'center',
  }
];

export const stepsMobile = [
  {
    targetId: 'search-button',
    content: (
      <Typography variant='body2'>Select a company to generate the annual report analysis.<br/><br/>
        <strong>Note:</strong> only US traded companies are supported for now.</Typography>
    ),
    arrowPosition: 'top-left',
    scrollPosition: 'start',
  },

  {
    targetId: 'summary',
    content: <Typography variant='body2'>Check quickly the most
      important <strong>Strengths</strong> and <strong>Concerns</strong>.</Typography>,
    arrowPosition: 'bottom-left',
    scrollPosition: 'center',
  },
  {
    targetId: 'side-by-side',
    content: <Typography variant='body2'>Find the answers of the most important questions here. </Typography>,
    arrowPosition: 'bottom-left',
    scrollPosition: 'center',
  },
  {
    targetId: 'first-question-sources',
    content: <Typography variant='body2'>See which parts of the document are relevant to this question. <strong>Click
      on any number</strong> and it will lead you to the <strong>exact location</strong> in the
      document.</Typography>,
    arrowPosition: 'top-left',
    scrollPosition: 'center',
  },
  {
    targetId: 'section-navigation',
    content: <Typography variant='body2'>Switch back and forth between the sections of the <strong>annual
      report</strong>.</Typography>,
    arrowPosition: 'bottom-left',
    scrollPosition: 'center',
  },
];
