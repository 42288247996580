import React from "react";
import {Box, Stack, Button, Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {paths} from "../../routes/paths";
import Iconify from "../iconify";

export default function DocumentAnalysisChoice() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box sx={{m: 10}} display="flex" flexDirection="column" alignItems="center">

      <Typography variant="body1" textAlign="center">Choose the type of document you want to analyze</Typography>
      <Stack direction={isMobile ? "column" : "row"} spacing={1} alignItems="center" justifyContent="center"
             sx={{mt: 2}}>
        <Button variant="contained"
                size="large"
                startIcon={<Iconify icon="line-md:phone-call-twotone-loop" width={28}/>}
                onClick={() => navigate(paths.main.document_analysis.earnings_call)}>
          Earnings Calls
        </Button>
        <Button variant="contained"
                size="large"
                startIcon={<Iconify icon="material-symbols:10k-sharp" width={30}/>}
                onClick={() => navigate(paths.main.document_analysis.annual_report)}>
          Annual Reports
        </Button>
      </Stack>
    </Box>

  )
}
