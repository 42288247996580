import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Iconify from '../iconify';
import {GoalsAndInitiativesType} from './types';
import EarningsQuote from "./earnings_quote";
import ExpandableTitle from "./expandable_title";

export default function RenderGoalsAndInitiatives({goalsAndInitiatives, paragraphs, onQuoteClick}: {
  goalsAndInitiatives: GoalsAndInitiativesType
  paragraphs: string[]
  onQuoteClick: (quote: string) => void
}) {
  const theme = useTheme();
  const title = (
    <Stack direction="row" spacing={0.5}>
      <Iconify icon="mingcute:target-line" sx={{color: theme.palette.primary.main}} width={30}/>
      <Typography variant="h6">{goalsAndInitiatives.question.question}</Typography>
    </Stack>
  );

  return (
    <Box sx={{mt: 3}}>
      <ExpandableTitle title={title}>
        {goalsAndInitiatives.answer.goals.map((goal, index) => {
          // Check if the quote is found in the document paragraphs
          const isQuoteInDocument = paragraphs.some(paragraph => paragraph.toLowerCase().includes(goal.quote_from_document.slice(0, -1).toLowerCase()));

          return (
            <React.Fragment key={index}>
              <Typography variant="body2" fontWeight={700} sx={{mt: 1}}>{goal.initiative}:</Typography>
              <Stack direction="row" spacing={0.5}>
                <Typography variant="body2">{goal.goal}</Typography>
                <EarningsQuote quote={goal.quote_from_document} onQuoteClick={onQuoteClick}
                               isQuoteInDocument={isQuoteInDocument}/>
              </Stack>
            </React.Fragment>
          );
        })}
      </ExpandableTitle>
    </Box>
  );
};
