// layouts
import {RouteWithHeader} from "../../layouts/custom-header/route-with-header";
import AnnualReportAnalysis from "../../components/documents_analysis/annual_report_analysis";
import AnnualReportAnalysisLoginGuard from "../../components/documents_analysis/annual_report_analysis_login_guard";
import DocumentAnalysisChoice from "../../components/documents_analysis/document-analysis-choice";
import EarningsCallAnalysis from "../../components/earnings_call_analysis/earnings_call_analysis";
import {paths} from "../paths";


export const analysisRoutes = [
    {
      path: paths.main.document_analysis.root,
      element: (

        <RouteWithHeader>
          <DocumentAnalysisChoice/>
        </RouteWithHeader>
      ),
    },
    {
      path: `${paths.main.document_analysis.annual_report}/:ticker?`,
      element: (

        <RouteWithHeader>
          <AnnualReportAnalysisLoginGuard>
            <AnnualReportAnalysis/>
          </AnnualReportAnalysisLoginGuard>
        </RouteWithHeader>
      ),
    },
    {
      path: `${paths.main.document_analysis.earnings_call}/:ticker?`,
      element: (
        <RouteWithHeader>
          <AnnualReportAnalysisLoginGuard>
            <EarningsCallAnalysis/>
          </AnnualReportAnalysisLoginGuard>
        </RouteWithHeader>
      ),
    }
  ]
;
