// @mui
import {alpha, useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Badge, {badgeClasses} from "@mui/material/Badge";
import LoginIcon from "@mui/icons-material/Login";
// hooks
import {useOffSetTop} from "src/hooks/use-off-set-top";
import {useResponsive} from "src/hooks/use-responsive";
import {useAuthContext} from "src/auth/hooks/use-auth-context";
// theme
import {bgBlur} from "src/theme/css";
// routes
import {paths} from "src/routes/paths";
// components
import Logo from "src/components/logo";
//
import React from "react";
import CustomPopover, {usePopover} from "src/components/custom-popover";
import {HEADER} from "../config-layout";
import {navConfig, navConfigMobile} from "./config-navigation";
import NavMobile from "./nav/mobile";
import NavDesktop from "./nav/desktop";
//
import {HeaderShadow} from "../_common";
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const mdUp = useResponsive("up", "lg");
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const {authenticated} = useAuthContext();
  const popover = usePopover();

  const contactUs = React.useRef<HTMLDivElement>(null);

  const handleMailto = () => {
    window.location.href = "mailto:contact@valuehunter.net?subject=Hi, I have a question.";
  };

  const handleDiscord = () => {
    window.open(process.env.REACT_APP_DISCORD_INVITATION, "_blank");
  };

  const openPopover = (event: React.MouseEvent<HTMLElement>) => {
    popover.onOpen(event);
  };

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(["height"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{height: 1, display: "flex", alignItems: "center"}}>
          {/* Desktop */}
          {mdUp && (
            <>
              <Badge
                sx={{
                  [`& .${badgeClasses.badge}`]: {
                    top: 8,
                    right: -16,
                  },
                }}
                badgeContent={
                  <Link
                    href={paths.main.root}
                    target="_blank"
                    rel="noopener"
                    underline="none"
                    sx={{ml: 1}}
                  >
                    {/*
                 <Label color="info" sx={{ textTransform: 'unset', height: 22, px: 0.5 }}>
                  v5.3.0
                </Label>
                */}
                  </Link>
                }
              >
                <Logo/>
              </Badge>
              <Box ml={5}> <NavDesktop offsetTop={offsetTop} data={navConfig}/></Box>
              <Stack sx={{
                cursor: "pointer",
                height: "40px",
                width: "10%",
                "&:hover": {
                  backgroundColor: () => alpha(theme.palette.action.hover, 0.1),
                },
              }}
              >
                <Box ref={contactUs}>

                  <Button variant="text" sx={{width: "100%"}}
                          onClick={(event: any) => popover.onOpen(event)}>
                    Contact Us <Iconify width={16} icon="solar:alt-arrow-down-line-duotone" sx={{ml: 1}}/>
                  </Button>
                </Box>

                <CustomPopover hiddenArrow
                               open={popover.open}
                               onClose={popover.onClose}
                               anchorEl={contactUs.current}
                               sx={{px: 2, py: 1}}
                               anchorOrigin={{
                                 vertical: "bottom",
                                 horizontal: "center",
                               }}
                               transformOrigin={{
                                 vertical: "top",
                                 horizontal: "right",
                               }}
                >
                  <Stack alignItems="left" direction="column">

                    <MenuItem onClick={handleMailto}>
                      <Iconify width={20} icon="fluent-emoji-flat:e-mail"/> Send us an Email
                    </MenuItem>
                    <MenuItem onClick={handleDiscord}>
                      <Iconify width={20} icon="skill-icons:discord"/> Join our Discord
                    </MenuItem>

                  </Stack>
                </CustomPopover>
              </Stack>


              <Box sx={{flexGrow: 1}}/>
              {!authenticated && (
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    href={`${paths.auth.jwt.login}`}
                  >
                    Login <LoginIcon sx={{ml: 1, width: 16}}/>
                  </Button>
                  <Button
                    variant="contained"
                    href={paths.auth.jwt.register}
                  >
                    Sign Up
                  </Button>
                </Stack>
              )}
            </>
          )}


          {/* Mobile */}
          {!mdUp &&
            (
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"
                     sx={{width: "100%"}}>
                <Badge
                  sx={{
                    [`& .${badgeClasses.badge}`]: {
                      top: 8,
                      right: -16,
                    },
                  }}
                  badgeContent={
                    <Link
                      href={paths.main.root}
                      target="_blank"
                      rel="noopener"
                      underline="none"
                      sx={{ml: 1}}
                    >
                      {/*
                 <Label color="info" sx={{ textTransform: 'unset', height: 22, px: 0.5 }}>
                  v5.3.0
                </Label>
                */}
                    </Link>
                  }
                >
                  <Logo/>
                </Badge>
                <Stack direction="row" spacing={1}>
                  <Box ref={contactUs}>

                    <Button variant="text"
                            onClick={(event: any) => popover.onOpen(event)}>
                      Contact Us <Iconify width={16} icon="solar:alt-arrow-down-line-duotone" sx={{ml: 1}}/>
                    </Button>
                  </Box>
                  <CustomPopover hiddenArrow
                                 open={popover.open}
                                 onClose={popover.onClose}
                                 anchorEl={contactUs.current}
                                 sx={{px: 2, py: 1}}
                                 anchorOrigin={{
                                   vertical: "bottom",
                                   horizontal: "center",
                                 }}
                                 transformOrigin={{
                                   vertical: "top",
                                   horizontal: "right",
                                 }}
                  >
                    <Stack alignItems="left" direction="column">

                      <MenuItem onClick={handleMailto}>
                        <Iconify width={20} icon="fluent-emoji-flat:e-mail"/> Send us an Email
                      </MenuItem>
                      <MenuItem onClick={handleDiscord}>
                        <Iconify width={20} icon="skill-icons:discord"/> Join our Discord
                      </MenuItem>

                    </Stack>
                  </CustomPopover>
                  <NavMobile offsetTop={offsetTop} data={navConfigMobile}/>
                </Stack>
              </Stack>
            )
          }

        </Container>
      </Toolbar>

      {
        offsetTop && <HeaderShadow/>
      }
    </AppBar>
  )
    ;
}
