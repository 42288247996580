import {ApexOptions} from 'apexcharts';
// @mui
import {styled, useTheme} from '@mui/material/styles';
import {CardProps} from '@mui/material/Card';
// components
import Chart, {useChart} from 'src/components/chart';
import numeral from "numeral";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 300;

const LEGEND_HEIGHT = 72;

const StyledChart = styled(Chart)(({theme}) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
    height: `100% !important`,
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    // borderTop: `dashed 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

interface Props extends CardProps {
  chart: {
    categories: (string | string[])[];
    colors?: string[];
    series: {
      name: string;
      data: number[];
    }[];
    options?: ApexOptions;
  };
}

export default function ScoreRadarStyled({ chart }: Props) {
  const theme = useTheme();
  const { series, colors, categories, options} = chart;
  const dataLabels = options?.dataLabels || {
     enabled: true,
      background: {
          enabled: true,
          borderRadius: 2,
      },
      formatter: (val) => numeral(val).format('0.0'),
  }
  const chartOptions = useChart({
      colors,
      stroke: {
          curve: 'straight',
          width: 3,
      },
      fill: {
          opacity: 0.48,
      },
      legend: {
          floating: true,
          position: 'bottom',
          horizontalAlign: 'center',
      },
      dataLabels,
      xaxis: {
          categories,
          labels: {
              style: {
                  colors: [...Array(6)].map(() => theme.palette.text.secondary),
              },
          },
      },
      yaxis: {
          min: 0,
          max: 5,
          tickAmount: 5,
      },
      ...options,
  });

  return (
      <StyledChart dir="ltr" type="radar" series={series} options={chartOptions} height={300} />
  );
}
