import axios, {AxiosRequestConfig} from 'axios';
// config
import {HOST_API} from 'src/config-global';
import {showError} from "../components/error/errorAlert";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({baseURL: HOST_API});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    console.error(error);
    const message = error.response.data.detail || error.message || 'Oops! Something went wrong.';
    showError({"message": message});
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, {...config});

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chats',
  companies: {
    get: '/api/companies',
    list: '/api/companies',
    documents: '/api/companies/documents',
    financials: '/api/companies/financials',
    revenueBreakdown: '/api/companies/revenue-breakdown',
    scored: '/api/companies/scored',
    ratios: '/api/companies/ratios',
    search: '/api/companies-search',
  },
  dashboard: {
    watchlist: '/api/watchlist',
    top_companies: '/api/top_companies',
  },
  screener: {
    get: '/api/screener',
  },
  survey: {
    get: '/api/surveys',
    responses: '/api/surveys/responses',
    skip: '/api/surveys/responses/skip',
  },
  ai_analysis: '/api/ai-analysis',
  ai_analysis_news: '/api/ai-analysis/news',
  document_analysis: '/api/document-analysis',
  earnings_call_analysis: '/api/earnings-call-analysis',
  user_credits: '/api/user_credits',
  predefined_prompts: '/api/predefined_prompts',
  subscription: {
    createSession: '/api/create-checkout-session',
  },
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
    forgot_password: '/api/auth/forgot-password',
    reset_password: '/api/auth/reset-password',
    verify: '/api/auth/verify',
  },
  google: {
    login: '/api/auth/google',
    callback: '/api/auth/google/callback',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
