import React, {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const quotes = [
  {text: "Price is what you pay. Value is what you get.", author: "Warren Buffett"},
  {
    text: "Whether we're talking about socks or stocks, I like buying quality merchandise when it is marked down.",
    author: "Warren Buffett"
  },
  {text: "The stock market is designed to transfer money from the Active to the Patient.", author: "Warren Buffett"},
  {
    text: "In the short run, the market is a voting machine, but in the long run, it is a weighing machine.",
    author: "Benjamin Graham"
  },
  {
    text: "The intelligent investor is a realist who sells to optimists and buys from pessimists.",
    author: "Benjamin Graham"
  },
  {
    text: "The essence of investment management is the management of risks, not the management of returns.",
    author: "Benjamin Graham"
  },
  {text: "A great business at a fair price is superior to a fair business at a great price.", author: "Charlie Munger"},
  {text: "You must value the business in order to value the stock.", author: "Charlie Munger"},
  {text: "Patience combined with opportunity is a great virtue in investing.", author: "Charlie Munger"},
  {
    text: "Value investing is at its core the marriage of a contrarian streak and a calculator.",
    author: "Seth Klarman"
  },
  {text: "The single greatest edge an investor can have is a long-term orientation.", author: "Seth Klarman"},
  {text: "Margin of safety remains the cornerstone of investment success.", author: "Seth Klarman"},
  {
    text: "The secret to investing is to figure out the value of something – and then pay a lot less.",
    author: "Joel Greenblatt"
  },
  {text: "Buying good businesses at bargain prices is what creates a margin of safety.", author: "Joel Greenblatt"},
  {
    text: "Investing isn't about beating others at their game. It's about controlling yourself at your own game.",
    author: "Benjamin Graham"
  },
  {text: "Time is the friend of the wonderful company, the enemy of the mediocre.", author: "Warren Buffett"},
  {text: "Risk comes from not knowing what you're doing.", author: "Warren Buffett"},
  {
    text: "No wise pilot, no matter how great his talent and experience, fails to use his checklist.",
    author: "Charlie Munger"
  },
  {text: "Value investors look at the asset as a business, not a ticker symbol.", author: "Seth Klarman"}
];

export default function AiProgressbarQuotes() {
  const [quoteIndex, setQuoteIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * quotes.length); // Generate a random index
      setQuoteIndex(randomIndex);
    }, 10000); // Change every 10 seconds

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  const currentQuote = quotes[quoteIndex];

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{
          maxWidth: "600px", // Max width for responsiveness
          px: 2, // Padding for smaller screens
          py: 3, // Padding for top and bottom
          mx: 3, // Margin on the sides
          borderRadius: 2,
          backgroundColor: "background.neutral", // Light background color
          textAlign: "center",
        }}
      >
        {/* Circular Progress Bar */}
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
          <Typography variant="body2" color="text.secondary">
            AI analysis generating... Hang tight!
          </Typography>
          <Typography variant="body2" color="text.secondary">
            It can take up to 30-40 seconds to complete. We are working on speeding this up!
          </Typography>
        </Stack>

        {/* Quote Text */}
        <Typography
          variant="h6"
          sx={{
            fontStyle: "italic", // Italic style for a quote
            fontSize: {xs: "1rem", md: "1.25rem"}, // Responsive font size
            px: {xs: 1, md: 2}, // Padding on small and medium screens
          }}
        >
          “{currentQuote.text}”
        </Typography>

        {/* Author Name */}
        <Typography
          variant="body2"
          color="text.secondary"
        >
          - {currentQuote.author}
        </Typography>
      </Stack>
    </Stack>
  );
}
