import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import Iconify from "../iconify";

export default function EarningsDialog({
  paragraphs,
  dialogOpen,
  setDialogOpen,
  searchString,
  date,
  quarter,
}: {
  paragraphs: string[];
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  searchString: string;
  date: string;
  quarter: string;
}) {
  useEffect(() => {
    if (dialogOpen && searchString) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          const substringElement = document.getElementById("highlighted-substring");
          if (substringElement) {
            substringElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        });
      }, 0);
    }
  }, [dialogOpen, searchString, paragraphs]);

  // Function to highlight the substring and assign an id for scrolling
  const highlightText = (text: string, search: string, paragraphIndex: number) => {
    if (!search) return text;

    const escapedSearch = search.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

    const regExp = new RegExp(`(${escapedSearch})`, "i");
    return text.replace(
      regExp,
      `<span id="highlighted-substring" style="background-color: yellow; font-weight: bold;">$1</span>`
    );
  };

  return (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify icon="material-symbols-light:call-log-outline" />
          <Typography variant="h6">Earnings Call</Typography>
        </Stack>

        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setDialogOpen(false)}
          aria-label="close"
          sx={{ position: "absolute", right: 14, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
          Q{quarter} | {new Date(date).getFullYear()}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: "pre-line" }}>
        {paragraphs.map((paragraph, index) => (
          <Box sx={{ mt: 1 }} key={index} id={`paragraph-${index}`}>
            {paragraph.split(/:(.*)/).map((text, idx) => (
              <Typography
                key={idx}
                variant="body2"
                fontWeight={idx === 0 ? 700 : 400}
                dangerouslySetInnerHTML={{
                  __html: highlightText(text.trim(), searchString, index),
                }}
              />
            ))}
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
