import {useLocation} from "react-router-dom";

import {useAuthContext} from "../../auth/hooks";
import {LoginToAccess} from "../utility_components/login-to-access";
import {BecomePremiumAccess} from "../utility_components/become-premium-access";
import {paths} from "../../routes/paths";

export default function AnnualReportAnalysisLoginGuard({children}: { children: React.ReactNode }) {
  // if authenticated or not authenticated but `share` query param is present, show the child component
  // else show the LoginToAccess component
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // get ticker from path

  let ticker = location.pathname.split('/').pop() || '';

  const earnings_call_path = paths.main.document_analysis.earnings_call.split('/').pop() || '';
  const annual_report_path = paths.main.document_analysis.annual_report.split('/').pop() || '';
  const isEarningsCall = location.pathname.includes(earnings_call_path);
  const featureUrl = isEarningsCall ? paths.main.document_analysis.earnings_call : paths.main.document_analysis.annual_report;

  // if document-analysis is contained in ticker, set ticker to ''
  if (ticker.includes(earnings_call_path) || ticker.includes(annual_report_path)) {
    ticker = '';
  }
  const share = queryParams.get('share');
  return (
    share ? (
      <>{children}</>
    ) : (
      <LoginToAccess returnTo={`${featureUrl}/${ticker}`} featureDescription="document analysis">
        <BecomePremiumAccess ticker={ticker}
                             returnTo={`${featureUrl}/${ticker}`}
                             featureDescription="the document analysis"
                             featureUrl={featureUrl}
                             imageUrl="/assets/images/blurred/10k-analysis.png"
                             showVisitedCompanies={false}
        >
          {children}
        </BecomePremiumAccess>
      </LoginToAccess>
    )
  );
}
