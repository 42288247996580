import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import AnnualReportContent from "./annual-report-content";


export default function AnnualReportAnalysisDialog({
                                                     html,
                                                     currentSourceId,
  currentSection,
                                                     dialogOpen,
                                                     setDialogOpen
                                                   }: {
  html: string,
  currentSourceId: string,
  currentSection: string,
  dialogOpen: boolean,
  setDialogOpen: (open: boolean) => void,
}) {
  return (
    <Box className="annual-report-analysis-container">
      <Dialog
        fullWidth
        maxWidth="xl"
        open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>AI Analysis

          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              setDialogOpen(false);
            }}
            aria-label="close"
            sx={{position: 'absolute', right: 14, top: 8}}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AnnualReportContent html={html} currentSourceId={currentSourceId} currentSection={currentSection}/>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between'}}>
          <Button onClick={() => setDialogOpen(false)}> Close</Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}
