import React, {useLayoutEffect, useRef} from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import {scrollToElement} from "../company/utils";
import {customScrollbarStyles} from "../../theme/css";


export default function AnnualReportContent({
                                              html,
                                              currentSourceId,
                                              currentSection
                                            }: {
  html: string,
  currentSourceId: string,
  currentSection: string,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const hasMountedSection = useRef(false);

  useLayoutEffect(() => {
    const markCurrentElement = () => {
      if (html && currentSourceId) {
        // Skip timeout for the first render
          setTimeout(() => {
            // remove all currentlySelected classes
            const elements = document.getElementsByClassName("currentlySelected");
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < elements.length; i++) {
              elements[i].classList.remove("currentlySelected");
            }

            // add currentlySelected class to the current source id
            const element = document.getElementById(`${currentSourceId}`);
            if (element) {
              element.classList.add("currentlySelected");
            }

            scrollToElement({ hash: `${currentSourceId}` });
          }, 1200);
      }
    };

    markCurrentElement();
  }, [html, currentSourceId]);

  useLayoutEffect(() => {
    const markCurrentSection = () => {
      if (html && currentSection) {
        // Skip timeout for the first render
        if (hasMountedSection.current) {
          setTimeout(() => {
            // remove all currentSection classes
            const elements = document.getElementsByClassName("currentSection");
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < elements.length; i++) {
              elements[i].classList.remove("currentSection");
            }

            // add currentSection class to all elements where id starts with currentSection
            const elementsInSection = document.querySelectorAll(`[id^="${currentSection}"]`);
            elementsInSection.forEach((element) => {
              element.classList.add("currentSection");
            });

            scrollToElement({ hash: `${currentSection}_1` }, "center");
          }, 1000);
        } else {
          // Set to true after first mount
          hasMountedSection.current = true;
        }
      }
    };

    markCurrentSection();
  }, [html, currentSection]);

  return (
    <Box className="annual-report-analysis-content">
      {/*
      <Alert
        variant="outlined"
        severity="info"
        sx={{mt: 2, width: 1}}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero.
        Sed cursus
      </Alert>
      */}
      <Box dangerouslySetInnerHTML={{__html: html}}
           sx={{
             m: isMobile ? 0 : 5,
             '.currentSection': {
               backgroundColor: theme.palette.grey[200],
               padding: 1,
               borderRadius: 0.5,
             },
             '.currentlySelected': {
               backgroundColor: theme.palette.info.light,
               padding: 1,
               borderRadius: 0.5,
               my: 1,
               transition: 'transform 2s ease-in-out',
               transform: 'scale(1.02)',
               fontSize: '14px !important',
             },
             ...customScrollbarStyles(theme),
           }}
      />
    </Box>
  );
}
