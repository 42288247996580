import React from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import {ParticipantsAnswerType} from './types';
import Iconify from "../iconify";

export default function ParticipantsDialog({
                                             participants,
                                             dialogOpen,
                                             setDialogOpen,

                                           }: {
  participants: ParticipantsAnswerType,
  dialogOpen: boolean,
  setDialogOpen: (open: boolean) => void
}) {
  return (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify icon="mdi:account-tie"/>
          <Typography variant="h6">Participants</Typography>
        </Stack>

        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setDialogOpen(false)}
          aria-label="close"
          sx={{position: "absolute", right: 14, top: 8}}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{whiteSpace: "pre-line"}}>
        {participants.answer.participants.map((participant, index) => (
          <Stack key={index} direction="row" spacing={0.5}>
            <Typography variant="body2" fontWeight={700}>{participant.position}:</Typography>
            <Typography variant="body2">{participant.name}</Typography>
          </Stack>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

