import React from 'react';
import {useTheme} from "@mui/material/styles";
import {Box} from "@mui/material";
import Iconify from '../iconify';

export default function EarningsQuote({quote, onQuoteClick, isQuoteInDocument}: {
  quote: string,
  onQuoteClick: (quote: string) => void,
  isQuoteInDocument: boolean
}) {
  const theme = useTheme();

  return (
    <Box>
      {isQuoteInDocument ? (
          <Box
            onClick={() => onQuoteClick(quote)}
            sx={{cursor: "pointer", position: "relative", width: "30px", height: "30px"}}
          >
            <Iconify
              icon="lets-icons:comment-duotone"
              width={26}
              sx={{color: theme.palette.text.secondary, position: "absolute", bottom: 18}}
            />
          </Box>
      ) : (
        // If the quote is not found in the document, show an empty box (no click action)
        <Box sx={{width: "30px", height: "30px"}} />
      )}
    </Box>
  );
}
