import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Collapse} from "@mui/material";
import Iconify from "../iconify";

const hoverStyles = {
  transition: 'transform 0.3s ease-in-out',
  "&:hover": {
    transform: 'scale(1.01)', // Slightly enlarge on hover
    color: "primary.main",
  },
}

export default function ExpandableTitle({
                                          initialExpanded = false,
                                          title,
                                          children
                                        }: {
  initialExpanded?: boolean,
  // title is an element that will be displayed as the title of the expandable section
  title: React.ReactNode,
  children: React.ReactNode
}) {
  const [isExpanded, setIsExpanded] = React.useState(initialExpanded);
  const downArrow = (<Iconify icon="eva:chevron-down-fill" width={24}/>);
  const upArrow = (<Iconify icon="eva:chevron-up-fill" width={24}/>);

  return (
    <>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          cursor: "pointer",
          ...hoverStyles
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Box>
          {title}
        </Box>
        <Box>
          {isExpanded ? upArrow : downArrow}
        </Box>
      </Stack>
      <Collapse in={isExpanded}>
        {children}
      </Collapse>

    </>
  )
}
