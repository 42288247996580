import React, {useCallback, useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Button, Grid, IconButton, Link as MuiLink} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import LinearProgress from "@mui/material/LinearProgress";
import ReactMarkdown from "react-markdown";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios, {endpoints} from "../../utils/axios";
import {customScrollbarStyles} from "../../theme/css";
import AnnualReportAnalysisDialog from "./annual-report-analysis-dialog";
import CustomSearchbar from "../../layouts/_common/custom-searchbar/custom-searchbar";
import {useBoolean} from "../../hooks/use-boolean";
import Iconify from "../iconify";
import {shouldUseLocalLogo} from "../company/constants";
import {CompanyDocumentsType, ScoredCompany2} from "../company/types";
import AnnualReportContent from "./annual-report-content";
import AiProgressbarQuotes from "../utility_components/ai-progressbar-quotes";
import ShowMore from "../utility_components/show-more";
import ReadingTime from "./reading-time";
import Tutorial from "./tutorial";
import {useSearchParams} from "../../routes/hooks";
import {stepsDesktop, stepsMobile} from "./tutorial-steps";
import CompanyHeader from "./company_header";
import {paths} from "../../routes/paths";

interface SectionAnalysisType {
  questions: {
    answer: string,
    question: string,
    subquestion: string,
    sources: string[],
  }[]
}

interface SummaryType {
  questions: {
    summary: string,
    concerns: string[],
    strengths: string[],
  }
}

interface AnalysisType {
  html: string,
  analysis: SectionAnalysisType,
  summary: SummaryType,
}

export default function AnnualReportAnalysis() {
  const {ticker} = useParams<{ ticker?: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [tutorialKey, setTutorialKey] = useState("tutorial_1");

  // get share_code from query params
  const shareCode = useSearchParams().get('share') || null;

  const [businessReadingTime, setBusinessReadingTime] = useState([0, 0]);
  const [riskFactorsReadingTime, setRiskFactorsReadingTime] = useState([0, 0]);
  const [mdnaReadingTime, setMdnaReadingTime] = useState([0, 0]);
  const [financialsReadingTime, setFinancialsReadingTime] = useState([0, 0]);
  const [currentReadingTimeAnalysis, setCurrentReadingTimeAnalysis] = useState(0);
  const [currentReadingTimeRealDocument, setCurrentReadingTimeRealDocument] = useState(0);


  const emptySectionAnalysis = {
    questions: [
      {
        answer: "",
        question: "",
        subquestion: "",
        sources: [],
      }
    ]
  }
  const emptySummary = {
    questions: {
      summary: "",
      concerns: [],
      strengths: [],
    }
  }

  const [businessAnalysis, setBusinessAnalysis] = useState<AnalysisType>({
    html: '',
    analysis: emptySectionAnalysis,
    summary: emptySummary,
  });
  const [riskFactorsAnalysis, setRiskFactorsAnalysis] = useState<AnalysisType>({
    html: '',
    analysis: emptySectionAnalysis,
    summary: emptySummary,
  });
  const [mdnaAnalysis, setMdnaAnalysis] = useState<AnalysisType>({
    html: '',
    analysis: emptySectionAnalysis,
    summary: emptySummary,
  });
  const [financialsAnalysis, setFinancialsAnalysis] = useState<AnalysisType>({
    html: '',
    analysis: emptySectionAnalysis,
    summary: emptySummary,
  });
  const [loading, setLoading] = useState(false);
  const [currentSourceId, setCurrentSourceId] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const [company, setCompany] = useState<ScoredCompany2 | null>(null)
  const [documents, setCompanyDocuments] = useState<CompanyDocumentsType | null>(null);
  const navigate = useNavigate();

  const [currentLocationHash, setCurrentLocationHash] = useState('business');
  const [currentSection, setCurrentSection] = useState('business');

  const getAnalysis = useCallback(async (tick: string) => {
    const sections_types = ['business', 'risk_factors', 'mdna', 'financials'];
    const sectionTypeToSetState = {
      business: setBusinessAnalysis,
      risk_factors: setRiskFactorsAnalysis,
      mdna: setMdnaAnalysis,
      financials: setFinancialsAnalysis,
    }

    try {
      setLoading(true);

      // Map each section type to an axios GET request and execute them in parallel
      const requests = sections_types.map((section) =>
        axios.get<any>(`${endpoints.document_analysis}/${tick}`, {
          params: {
            share: shareCode,
            section,  // Add the "section" parameter
          },
        })
      );

      // Execute all requests in parallel using Promise.all
      const results = await Promise.all(requests);

      // Map the results to the corresponding section type
      results.forEach((result, index) => {
        // @ts-ignore
        sectionTypeToSetState[sections_types[index]](result.data);
      });

    } catch (error) {
      console.error("Error fetching annual report analysis", error);
    } finally {
      setLoading(false);
    }
  }, [shareCode]);

  useEffect(() => {
    if (ticker) {
      getAnalysis(ticker);
    }
  }, [getAnalysis, ticker]);

  const calculateReadingTime = (text: string): number => {
    const wordsPerMinute = 200; // Average reading speed
    const words = text.trim().split(/\s+/).length; // Split by whitespace to count words
    // Rounding up to whole minutes
    return Math.ceil(words / wordsPerMinute);
  };

  useEffect(() => {
    // get only the html body
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(businessAnalysis.html, 'text/html');
    const {body} = htmlDoc;
    // get only the text content of elements whose id starts with business_
    // concatenate all the text content
    const businessText = Array.from(body.querySelectorAll('[id^="business_"]')).map((el) => el.textContent).join(' ');
    const riskFactorsText = Array.from(body.querySelectorAll('[id^="risk_factors_"]')).map((el) => el.textContent).join(' ');
    const mdnaText = Array.from(body.querySelectorAll('[id^="mdna_"]')).map((el) => el.textContent).join(' ');
    const financialsText = Array.from(body.querySelectorAll('[id^="financials_"]')).map((el) => el.textContent).join(' ');

    // calculate reading time of the business analysis
    const analysisBusinessText = businessAnalysis.analysis.questions?.map((a: any) => a.answer).join(' ');
    const analysisBusinessSummaryText = businessAnalysis.summary.questions.summary + businessAnalysis.summary.questions.concerns.join(' ') + businessAnalysis.summary.questions.strengths.join(' ');
    const businessSectionText = analysisBusinessText + analysisBusinessSummaryText;
    setBusinessReadingTime([calculateReadingTime(businessSectionText), calculateReadingTime(businessText)]);

    // calculate reading time of the risk factors analysis
    const analysisRiskFactorsText = riskFactorsAnalysis.analysis.questions?.map((a: any) => a.answer).join(' ');
    const analysisRiskFactorsSummaryText = riskFactorsAnalysis.summary.questions.summary + riskFactorsAnalysis.summary.questions.concerns.join(' ') + riskFactorsAnalysis.summary.questions.strengths.join(' ');
    const riskFactorsSectionText = analysisRiskFactorsText + analysisRiskFactorsSummaryText;
    setRiskFactorsReadingTime([calculateReadingTime(riskFactorsSectionText), calculateReadingTime(riskFactorsText)]);

    // calculate reading time of the mdna analysis
    const analysisMdnaText = mdnaAnalysis.analysis.questions?.map((a: any) => a.answer).join(' ');
    const analysisMdnaSummaryText = mdnaAnalysis.summary.questions.summary + mdnaAnalysis.summary.questions.concerns.join(' ') + mdnaAnalysis.summary.questions.strengths.join(' ');
    const mdnaSectionText = analysisMdnaText + analysisMdnaSummaryText;
    setMdnaReadingTime([calculateReadingTime(mdnaSectionText), calculateReadingTime(mdnaText)]);

    // calculate reading time of the financials analysis
    const analysisFinancialsText = financialsAnalysis.analysis.questions?.map((a: any) => a.answer).join(' ');
    const analysisFinancialsSummaryText = financialsAnalysis.summary.questions.summary + financialsAnalysis.summary.questions.concerns.join(' ') + financialsAnalysis.summary.questions.strengths.join(' ');
    const financialsSectionText = analysisFinancialsText + analysisFinancialsSummaryText;
    setFinancialsReadingTime([calculateReadingTime(financialsSectionText), calculateReadingTime(financialsText)]);

  }, [businessAnalysis, riskFactorsAnalysis, mdnaAnalysis, financialsAnalysis]);

  useEffect(() => {
    if (currentLocationHash === "business") {
      setCurrentReadingTimeAnalysis(businessReadingTime[0]);
      setCurrentReadingTimeRealDocument(businessReadingTime[1]);
    } else if (currentLocationHash === "risk_factors") {
      setCurrentReadingTimeAnalysis(riskFactorsReadingTime[0]);
      setCurrentReadingTimeRealDocument(riskFactorsReadingTime[1]);
    } else if (currentLocationHash === "mdna") {
      setCurrentReadingTimeAnalysis(mdnaReadingTime[0]);
      setCurrentReadingTimeRealDocument(mdnaReadingTime[1]);
    } else if (currentLocationHash === "financials") {
      setCurrentReadingTimeAnalysis(financialsReadingTime[0]);
      setCurrentReadingTimeRealDocument(financialsReadingTime[1]);
    }
  }, [currentLocationHash, businessReadingTime, riskFactorsReadingTime, mdnaReadingTime, financialsReadingTime]);

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.companies.get}/${ticker}`);
        setCompany(result.data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    const getCompanyDocuments = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.companies.documents}/${ticker}`);
        setCompanyDocuments(result.data);
      } catch (error) {
        console.error('Error fetching company documents:', error);
      }
    };

    if (ticker) {
      getCompanyData();
      getCompanyDocuments();
    }
  }, [ticker]);


  if (loading) {
    return (
      <>
        <Box>
          <LinearProgress/>
        </Box>
        <Box sx={{mt: 5}}>
          <AiProgressbarQuotes/>
        </Box>
      </>
    )
  }

  const renderSources = (sources: string[], sourcesPrefix: string) => sources.map((source, idx) => (
    <Typography
      id={sourcesPrefix === "business_" ? "first-question-sources" : ""}
      key={`${source}-${idx}`}
      variant="caption"
      onClick={() => {
        setCurrentSourceId(sourcesPrefix + source);
        setDialogOpen(true);
      }}
      sx={{
        cursor: 'pointer',
        color: theme.palette.primary.main,
        display: 'inline',
        verticalAlign: 'super',
        fontSize: '0.85rem', // You can adjust the font size if needed
        '&:not(:last-of-type)': {
          '&:after': {
            content: '","',
          },
        },
      }}
    >{idx + 1}</Typography>
  ));

  const renderCompanyHeader = (comp: ScoredCompany2) => {
    const path = `../../assets/images/company_logos/${comp.ticker}.png`;
    return (
      <Stack direction="row"
             spacing={2}
             sx={{width: '100%'}}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Box>
            <Box
              component="img"
              sx={{
                maxHeight: 60,
                maxWidth: 60,
              }}
              alt="Company logo"
              src={shouldUseLocalLogo.has(comp.ticker) ? path : comp.image_url}
            />
          </Box>
          <Box sx={{ml: '20px'}}>
            <Box>
              <Typography id="company-info" variant="h5" sx={{color: 'text.primary', mb: 1}}>
                {comp.company_name}
              </Typography>
              <Typography variant="body1" sx={{color: 'text.secondary'}}>
                ${comp.ticker}
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Box>

          <Button
            color="primary"
            size="small"
            variant="text"
            sx={{borderColor: 'text.primary'}}
            startIcon={<Iconify icon="eva:external-link-fill" width={16}/>}
            component={Link}
            to={`/companies/${comp.ticker}`}
            target="_blank"

          >
            View
          </Button>
        </Box>

      </Stack>)
  }

  const renderReportHeader = () => (
    <Stack direction="row" spacing={2} justifyContent="flex-start">
      <Stack direction="row"
             alignItems="center"
             justifyContent="center"
             spacing={1}>
        <Typography variant="caption">10-K</Typography>
        <Typography variant="caption">|</Typography>
        {/* @ts-ignore */}
        <Typography variant="caption">{businessAnalysis?.document_date}</Typography>
        <Typography variant="caption">|</Typography>
        <Typography variant="caption">
          {/* @ts-ignore */}
          <MuiLink href={documents?.latest_annual_url} target="_blank">original document <Iconify
            icon="eva:external-link-fill" width={10}/></MuiLink>
        </Typography>
      </Stack>
    </Stack>
  );

  const renderSummary = () => {
    if (currentLocationHash === 'business') {
      return renderSummaryLayout("Item 1. Business", businessAnalysis.summary.questions.summary, businessAnalysis.summary.questions.concerns, businessAnalysis.summary.questions.strengths);
    }
    if (currentLocationHash === 'risk_factors') {
      return renderSummaryLayout("Item 1A. Risk Factors", riskFactorsAnalysis.summary.questions.summary, riskFactorsAnalysis.summary.questions.concerns, riskFactorsAnalysis.summary.questions.strengths);
    }
    if (currentLocationHash === 'mdna') {
      return renderSummaryLayout("Item 7. Management's Discussion", mdnaAnalysis.summary.questions.summary, mdnaAnalysis.summary.questions.concerns, mdnaAnalysis.summary.questions.strengths);
    }
    if (currentLocationHash === 'financials') {
      return renderSummaryLayout("Item 8: Financials", financialsAnalysis.summary.questions.summary, financialsAnalysis.summary.questions.concerns, financialsAnalysis.summary.questions.strengths);
    }
    return null;
  }


  const renderSummaryLayout = (header: string, summary: string, concerns: string[], strengths: string[]) => (
    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{width: '100%'}}>
      {/*
      */}
      <Grid item xs={12}>
        <Box sx={{width: "100%"}}>
          <Typography variant="h6">📋 {header}</Typography>
          {/*
          <Typography variant="body2">
            {summary}
          </Typography>
          */}

        </Box>
      </Grid>

      <Grid item xs={12} lg={6}>

        <Stack direction="column" spacing={0.5}
               sx={{mt: 1, width: "100%", backdropFilter: 'blur(10px)'}}>
          <Typography variant="h6">Strengths</Typography>
          {strengths && strengths?.map((text, index) => (
            <Box key={index}>
              <Stack direction="row" spacing={0.8}>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Iconify
                    width={24}
                    icon='line-md:check-all'
                    sx={{
                      color: 'success.main',
                    }}/>
                </Box>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Typography variant="body2" sx={{
                    color: 'text.secondary',
                  }}>
                    {text}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>


      </Grid>
      <Grid item xs={12} lg={6}>

        <Stack direction="column" spacing={0.5}
               sx={{mt: 1, width: "100%", backdropFilter: 'blur(10px)'}}>
          <Typography variant="h6">Concerns</Typography>
          {concerns && concerns?.map((text, index) => (
            <Box key={index}>
              <Stack direction="row" spacing={0.8}>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Iconify
                    width={24}
                    icon='fluent:warning-28-filled'
                    sx={{
                      color: 'error.main',
                    }}/>
                </Box>
                <Box component="span" sx={{color: 'text.secondary'}}>
                  <Typography variant="body2" sx={{
                    color: 'text.secondary',
                  }}>
                    {text}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );


  const handleCompanyAddClick = async (tick: string, onDialogClose: () => void) => {
    // setTicker(tick);
    navigate(`${paths.main.document_analysis.annual_report}/${tick}`);
  }

  const renderNavigationDesktop = (shouldScroll: boolean = true) => (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="start">
      <Button
        id={!shouldScroll ? "section-navigation" : ""}
        size="large"
        startIcon={<Iconify icon="healthicons:money-bag"/>}
        onClick={() => {
          setCurrentLocationHash('business');
          if (shouldScroll) {
            setCurrentSection('business');
          }
        }}
        sx={{
          borderBottom: currentLocationHash === 'business' ? '2px solid' : 'none',
          borderRadius: 0
        }}
      >
        Business
      </Button>
      <Button
        size="large"
        startIcon={<Iconify icon="solar:danger-bold"/>}
        onClick={() => {
          setCurrentLocationHash('risk_factors');
          if (shouldScroll) {
            setCurrentSection('risk_factors');
          }
        }}
        sx={{
          borderBottom: currentLocationHash === 'risk_factors' ? '2px solid' : 'none',
          borderRadius: 0
        }}
      >
        Risks
      </Button>
      <Button
        size="large"
        startIcon={<Iconify icon="hugeicons:manager"/>}
        onClick={() => {
          setCurrentLocationHash('mdna');
          if (shouldScroll) {
            setCurrentSection('mdna');
          }
        }}
        sx={{
          borderBottom: currentLocationHash === 'mdna' ? '2px solid' : 'none',
          borderRadius: 0
        }}
      >
        Management
      </Button>
      <Button
        size="large"
        startIcon={<Iconify icon="carbon:finance"/>}
        onClick={() => {
          setCurrentLocationHash('financials');
          if (shouldScroll) {
            setCurrentSection('financials');
          }
        }}
        sx={{
          borderBottom: currentLocationHash === 'financials' ? '2px solid' : 'none',
          borderRadius: 0
        }}
      >
        Financials
      </Button>
    </Stack>
  );

  const renderNavigationMobile = (
    <Box
      id="section-navigation"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
        py: 0.5,
      }}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        <IconButton
          onClick={() => setCurrentLocationHash('business')}
          sx={{
            borderBottom: currentLocationHash === 'business' ? '2px solid' : 'none',
            borderRadius: 0
          }}
        >
          <Stack direction="column" alignItems="center">
            <Iconify icon="healthicons:money-bag"/>
            <Typography variant="body2" sx={{fontSize: 10}}>Business</Typography>
          </Stack>
        </IconButton>
        <IconButton
          onClick={() => setCurrentLocationHash('risk_factors')}
          sx={{
            borderBottom: currentLocationHash === 'risk_factors' ? '2px solid' : 'none',
            borderRadius: 0
          }}
        >
          <Stack direction="column" alignItems="center">
            <Iconify icon="solar:danger-bold"/>
            <Typography variant="body2" sx={{fontSize: 10}}>Risks</Typography>
          </Stack>
        </IconButton>
        <IconButton
          onClick={() => setCurrentLocationHash('mdna')}
          sx={{
            borderBottom: currentLocationHash === 'mdna' ? '2px solid' : 'none',
            borderRadius: 0
          }}
        >
          <Stack direction="column" alignItems="center">
            <Iconify icon="hugeicons:manager"/>
            <Typography variant="body2" sx={{fontSize: 9.5}}>Management Discussion</Typography>
          </Stack>
        </IconButton>
        <IconButton
          onClick={() => setCurrentLocationHash('financials')}
          sx={{
            borderBottom: currentLocationHash === 'financials' ? '2px solid' : 'none',
            borderRadius: 0
          }}
        >
          <Stack direction="column" alignItems="center">
            <Iconify icon="carbon:finance"/>
            <Typography variant="body2" sx={{fontSize: 10}}>Financials</Typography>
          </Stack>
        </IconButton>
      </Stack>
    </Box>
  );

  const renderBusinessAnalysis = (
    <>

      {/* @ts-ignore */}
      {businessAnalysis.analysis?.questions && businessAnalysis.analysis.questions.map((a, index) => (
        <Box
          // eslint-disable-next-line react/jsx-curly-brace-presence
          key={`business_key_${index}`}
          sx={{mt: 3, fontSize: '0.9rem'}}>
          {index !== 0 && <Divider sx={{mb: 2}}/>}
          <Typography
            variant="h4">{index + 1}. {a.question} {renderSources(a.sources, "business_")}</Typography>

          <ShowMore initialExpanded={index === 0} expandText="Show Answer" collapseText="Hide Answer">
            <ReactMarkdown>{a.answer}</ReactMarkdown>
          </ShowMore>
        </Box>
      ))}
    </>
  );

  const renderRisksAnalysis = (
    <>
      {/* @ts-ignore */}
      {riskFactorsAnalysis.analysis?.questions && riskFactorsAnalysis.analysis.questions.map((a, index) => (
        <Box
          key={`risk_factors_key_${index}`}
          sx={{mt: 3, fontSize: '0.9rem'}}>
          {index !== 0 && <Divider sx={{mb: 2}}/>}
          <Typography
            variant="h4">{a.question} {renderSources(a.sources, "risk_factors_")}</Typography>
          {/*
          <Typography variant="body2" color="text.secondary">{a.subquestion}</Typography>
          */}

          <ShowMore initialExpanded={index === 0}>
            <ReactMarkdown>{a.answer}</ReactMarkdown>
          </ShowMore>
        </Box>
      ))}
    </>
  );

  const renderManagementAnalysis = (
    <>

      {/* @ts-ignore */}
      {mdnaAnalysis.analysis?.questions && mdnaAnalysis.analysis.questions.map((a, index) => (
        <Box
          key={`mdna_key_${index}`}
          sx={{mt: 3, fontSize: '0.9rem'}}>
          {index !== 0 && <Divider sx={{mb: 2}}/>}
          <Typography
            variant="h4">{a.question} {renderSources(a.sources, "mdna_")}</Typography>
          {/*
          <Typography variant="body2" color="text.secondary">{a.subquestion}</Typography>
          */}

          <ShowMore initialExpanded={index === 0}>
            <ReactMarkdown>{a.answer}</ReactMarkdown>
          </ShowMore>


        </Box>
      ))}
    </>
  );

  const renderFinancialsAnalysis = (
    <>
      {/* @ts-ignore */}
      {financialsAnalysis.analysis?.questions && financialsAnalysis.analysis.questions.map((a, index) => (
        <Box
          key={`financials_key_${index}`}
          sx={{mt: 3, fontSize: '0.9rem'}}>
          {index !== 0 && <Divider sx={{mb: 2}}/>}
          <Typography
            variant="h4">{a.question} {renderSources(a.sources, "financials_")}</Typography>
          {/*
          <Typography variant="body2" color="text.secondary">{a.subquestion}</Typography>
          */}

          <ShowMore initialExpanded={index === 0}>
            <ReactMarkdown>{a.answer}</ReactMarkdown>
          </ShowMore>
        </Box>
      ))}
    </>
  );

  const renderSearchButton = (search: ReturnType<typeof useBoolean>) => (
    <Button
      color="inherit"
      variant="outlined"
      startIcon={<Iconify icon="eva:search-outline" width={24}/>}
      onClick={() => search.onTrue()}
      sx={{mt: 1, borderColor: 'text.primary'}}
    >
      Select Company
    </Button>
  );

  const renderAnalysisWithTabs = (
    <>
      {/* search button */}
      <Stack direction="row" spacing={2} alignItems="center" justifyContent={isMobile ? "flex-start" : "center"}>
        <Box id="search-button" sx={{mt: 1, position: "relative"}}>
          <CustomSearchbar
            renderSearchButton={renderSearchButton}
            handleCompanyClickCustom={handleCompanyAddClick}
            country="us"
            tickersToHide={[]}/>
          <Button
            size="small"
            color="primary"
            sx={{fontSize: "12px", position: "absolute", bottom: 20, width: "105px"}}

            onClick={() => {
              // delete the key from local storage and reload the tutorial
              localStorage.removeItem("reportAnalysisTutorialPassed20240921");
              setTutorialKey(`tutorial_${Date.now()}`);
            }}
          >
            How this works?
          </Button>
        </Box>
      </Stack>


      {/* company header */}
      {ticker && company?.ticker && <CompanyHeader company={company}/>}

      {/* navigation */}
      {!isMobile && (
        <Box>
          {renderNavigationDesktop(false)}
        </Box>
      )}


      {/* render summary */}
      <Typography id="summary" variant="h4" sx={{mt: 2}}>Summary</Typography>
      {isMobile &&
        <ReadingTime timeInMinsAnalysis={currentReadingTimeAnalysis} timeInMinsReal={currentReadingTimeRealDocument}/>}
      {renderSummary()}

      {!isMobile && (<Typography id="side-by-side" variant="h4" sx={{mt: 2}}>Side by Side Analysis</Typography>)}
      {isMobile && (<Typography id="side-by-side" variant="h4" sx={{mt: 2}}>Analysis</Typography>)}

      {/* arrows */}
      {!isMobile && (
        <>
          <Box>
            {renderNavigationDesktop(true)}
          </Box>
          <Grid container spacing={2} justifyContent="center" alignItems="center"
                sx={{width: '100%'}}>
            <Grid item xs={12} md={6}>
              <Stack direction="column" alignItems="center" justifyContent="center">
                <Typography variant="h6">AI Analysis</Typography>
                {/*
              <Iconify icon="mingcute:arrow-down-fill" width={32} height={32}/>
              */}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="column" alignItems="center" justifyContent="center">
                <Typography variant="h6">Original Document</Typography>
                {/*
              <Iconify icon="mingcute:arrow-down-fill" width={32} height={32}/>
              */}
              </Stack>
            </Grid>
          </Grid>

        </>

      )}

      {/* analysis */}
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{width: '100%'}}>
        <Grid item md={12} lg={6}>
          {/* render reading time */}
          {!isMobile && <ReadingTime timeInMinsAnalysis={currentReadingTimeAnalysis}
                                     timeInMinsReal={currentReadingTimeRealDocument}/>}
          <Box
            sx={isMobile ? {
              m: 0
            } : {
              overflowY: 'auto',
              ...customScrollbarStyles(theme),
              height: '90vh',
              width: '100%',
              mx: 2
            }}

          >
            {currentLocationHash === 'business' && renderBusinessAnalysis}
            {currentLocationHash === 'risk_factors' && renderRisksAnalysis}
            {currentLocationHash === 'mdna' && renderManagementAnalysis}
            {currentLocationHash === 'financials' && renderFinancialsAnalysis}
          </Box>
        </Grid>

        {!businessAnalysis.html && (
          <Typography variant="body1">No analysis available</Typography>
        )}

        {businessAnalysis.html && !isMobile && (
          <Grid item md={12} lg={6}>
            {renderReportHeader()}
            <Box
              sx={{
                overflowY: 'auto',
                ...customScrollbarStyles(theme),
                height: '90vh',
                width: '100%',
                mx: 2
              }}
            >

              <AnnualReportContent html={businessAnalysis.html} currentSourceId={currentSourceId}
                                   currentSection={currentSection}/>
            </Box>
          </Grid>
        )}

        {businessAnalysis.html && isMobile && (
          <AnnualReportAnalysisDialog
            html={businessAnalysis.html}
            currentSourceId={currentSourceId}
            currentSection={currentSection}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}/>
        )}

      </Grid>

      {isMobile && (
        <Box sx={{height: '40px'}}>
          {renderNavigationMobile}
        </Box>
      )}

    </>

  )

  const renderNoAnalysis = (
    <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{mt: 5}}>
      <Typography variant="h5">Please select annual report.</Typography>
      <CustomSearchbar
        renderSearchButton={renderSearchButton}
        handleCompanyClickCustom={handleCompanyAddClick}
        country="us"
        tickersToHide={[]}/>
    </Stack>
  );
  return (
    <Stack direction="column" spacing={2} sx={{height: '100%', my: 2, mx: isMobile ? 2 : 5}}>
      {businessAnalysis?.analysis.questions[0].answer === "" && renderNoAnalysis}

      {businessAnalysis?.analysis.questions[0].answer !== "" && (
        <>
          {renderAnalysisWithTabs}
          <Tutorial key={tutorialKey} storageKey="reportAnalysisTutorialPassed20240921"
                    steps={isMobile ? stepsMobile : stepsDesktop}/>

        </>
      )}
    </Stack>
  );
}
