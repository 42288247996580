import React from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useLocation} from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Iconify from "src/components/iconify";
import CustomSearchbar from "../_common/custom-searchbar";
import {paths} from "../../routes/paths";
import {RouterLink} from "../../routes/components";
import {AccountPopover} from "../_common";
import {useAuthContext} from "../../auth/hooks";
import RemainingCredits from "../../components/remaining-credits/remaining-credits";
import JoinDiscord from "../../components/join-discord/join-discord";
import Label from "../../components/label";


export default function CustomHeader() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10, // Adjust this value as needed, smaller value means less scroll needed
  });
  const {authenticated, user} = useAuthContext();
  const theme = useTheme();

  const isMobile = useMediaQuery("(max-width:1000px)");
  const location = useLocation();
  const [showBasicPlanBanner, setShowBasicPlanBanner] = React.useState(true);

  // set returnTo to the current location
  const returnTo = location.pathname + location.search;

  const renderNavigationButtons = () => {
    if (isMobile) {
      return null;
    }

    return (
      <Stack direction="row" alignItems="center" spacing={3} sx={{height: "100%"}}>
        <Button
          component={RouterLink}
          href={paths.main.dashboard}
          sx={{color: "text.secondary", width: "100%"}}
          startIcon={<Iconify icon="material-symbols:dashboard" width={24}/>}>
          Dashboard
        </Button>
        <Button
          component={RouterLink}
          href={paths.main.screener}
          sx={{color: "text.secondary", width: "100%"}}
          startIcon={<Iconify icon="material-symbols:search-insights" width={24}/>}>
          Screener
        </Button>
        <Stack direction="row">
          <Button
            component={RouterLink}
            href={paths.main.document_analysis.root}
            sx={{color: "text.secondary", width: "100%", whiteSpace: "nowrap"}}
            startIcon={<Iconify icon="line-md:document-report" width={24}/>}>
            Report Analysis
          </Button>

          <Box sx={{position: "relative"}}>
            <Label color="info" sx={{position: "absolute", bottom: 15}}>Beta</Label>
          </Box>
        </Stack>
      </Stack>
    );
  }

  const renderAuthenticated = () => (
    <>
      {!isMobile && (
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{width: "100%", mx: 1}}>
          {renderNavigationButtons()}
          <Stack direction="row" alignItems="center" spacing={3} sx={{height: "100%"}}>
            <JoinDiscord/>
            <RemainingCredits/>
            <AccountPopover/>
          </Stack>
        </Stack>

      )}
      {isMobile && (
        <Stack direction="row" alignItems="center" spacing={3} sx={{height: "100%"}}>

          <RemainingCredits/>
          <Box sx={{mx: 1, cursor: "pointer"}} alignItems="center">
            <AccountPopover/>
          </Box>
        </Stack>
      )}
    </>
  );

  const renderBasicPlanBanner = () => {
    // if location is /plans, don't show the banner
    if (location.pathname === paths.main.plans) {
      return null;
    }
    // if user is not authenticated, don't show the banner
    if (!authenticated) {
      return null;
    }
    if (user?.subscription?.status === "active" || user?.subscription?.status === "trialing") {
      return null;
    }

    if (!showBasicPlanBanner) {
      return null;
    }

    return (
      <Box className="header-banner" sx={{
        width: "100%",
        bgcolor: theme.palette.info.light,
        zIndex: 1000,
        py: 1,
        position: "relative",
      }} justifyContent="center">
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
          <Typography variant="body2" textAlign="center">
            Start 10-day free trial now and analyze like a Pro.
          </Typography>
          <Button variant="contained" href={paths.main.plans}>
            ⚡️ Start Free Trial ⚡️
          </Button>
        </Stack>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            setShowBasicPlanBanner(false);
          }}
          aria-label="close"
          sx={{
            position: "absolute",
            right: {xs: 10, md: 20},
            top: 0,
            bottom: 0,
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "transparent",
            },
          }}
        >
          <CloseIcon/>
        </IconButton>
      </Box>
    );
  };

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <Box>

        <Box className="header-container"
             sx={{height: "60px", bgcolor: "background.neutral", zIndex: 1000}}>
          <Stack direction="row" spacing={1} sx={{height: "100%"}} justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1} sx={{height: "100%"}}>
              <Box sx={{mx: 2, height: "50px", width: "50px", cursor: "pointer"}}>
                <Link href={paths.main.root} component={RouterLink}>
                  <img className="logo" src="/assets/images/logo/logo.png" alt="logo"/>
                </Link>
              </Box>
              <CustomSearchbar/>
            </Stack>
            {authenticated ? (
              renderAuthenticated()
            ) : (
              <Stack direction="row" alignItems="center" justifyContent={isMobile ? "flex-end" : "space-between"} sx={{width: "100%", mx: 1}}>
                {renderNavigationButtons()}
                <Stack direction="row" alignItems="center">
                  <Button href={`${paths.auth.jwt.login}?returnTo=${returnTo}`}>
                    Login <LoginIcon sx={{ml: 1, width: 16}}/>
                  </Button>
                  <Button href={`${paths.auth.jwt.register}?returnTo=${returnTo}`}>Sign Up</Button>
                </Stack>
              </Stack>

            )}
          </Stack>

        </Box>
        {renderBasicPlanBanner()}

      </Box>

    </Slide>
  );
}
